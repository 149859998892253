import { Session, SupabaseClient } from "@supabase/supabase-js";
import { AutoComplete, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Modal from "antd/es/modal/Modal";
import {v4 as uuidv4} from 'uuid';
import { useGlobalState } from "../GlobalState";
import { DefaultOptionType } from "antd/es/select";
import * as fuzzysort from "fuzzysort";
import { filterTitle } from "../../../util/filterutil";

interface Props {
  isModalOpen: boolean,
  setIsModalOpen: (b: boolean) => void,
  setForceRefresh: (b: boolean) => void,
}

export default function CreateMapModal({ isModalOpen, setIsModalOpen, setForceRefresh}: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  
  const [submitLoading, setSubmitLoading] = useState(false);

  const editingGameId = useGlobalState((state) => state.editingGameId);

  const [backgroundImage, setBackgroundImage] = useState('');

  const [fullOptions, setFullOptions] = useState<DefaultOptionType[]>([]);
  const [backgroundOptions, setBackgroundOptions] = useState<DefaultOptionType[]>([]);

  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    form.validateFields();
  }, [form]);

  useEffect(() => {
    form.validateFields().then(() => {
      const backgroundImageValue = form.getFieldValue('background_image');
      setSubmitEnabled(fullOptions.filter((item) => item.value == backgroundImageValue).length > 0);
    }, () => {
      setSubmitEnabled(false);
    })
  }, [backgroundImage]);

  useEffect(() => {
    if (!isModalOpen)
      return;

    const getData = async () => {
      const {error, data} = await supabase
        .from('images')
        .select('file_name')
        .eq('owner_id', session.user.id);

      if (error || !data) {
        messageApi.open({
          type: 'error',
          content: 'Could not fetch uploads'
        });
        setFullOptions([]);
        return;
      }

      const newOptions = data.map((item) => ({
        value: item.file_name,
      } as DefaultOptionType));
      console.log(newOptions);
      setFullOptions(newOptions);
    };

    getData();
  }, [isModalOpen, setFullOptions]);

  useEffect(() => {
    if (!fullOptions)
      return;

    let result: DefaultOptionType[] = [];
    if (backgroundImage && backgroundImage.length > 0) {
      fuzzysort.cleanup();
      result = fuzzysort.go(backgroundImage, fullOptions, {key:'value'}).map((i) => {
        return {
          ...i.obj,
          label: filterTitle((i as any).obj.value, (i as any)._indexes)
        }
      });
    } else
      result = fullOptions;
    setBackgroundOptions(result);
  }, [fullOptions, backgroundImage]);

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 28 },
  };

  return (
    <>
      {contextHolder}
      <Modal title="Create New Map" open={isModalOpen} onOk={() => {
        const { display_name, background_image } = form.getFieldsValue();

        if (!display_name || !background_image) {
          messageApi.open({
            type: 'error',
            content: 'Please provide a display name and map'
          });
        }

        setSubmitLoading(true);

        const makeMap = async () => {
          // Find the image that matches
          const {error: imageError, data: imageData} = await supabase
            .from('images')
            .select('file_name,width,height')
            .eq('owner_id', session.user.id)
            .eq('file_name', background_image)
            .limit(1)
            .single();

          if (!imageData) {
            messageApi.open({
              type: 'error',
              content: 'Could not find that uploaded image'
            });

            setSubmitLoading(false);
            return;
          }

          const {error} = await supabase
            .from('maps')
            .insert({
              map_id: uuidv4(),
              owner_id: session.user.id,
              game_id: editingGameId,
              display_name: display_name,
              background_image: background_image,
              projection_x: imageData.width,
              projection_y: imageData.height,
            });

          if (error) {
            messageApi.open({
              type: 'error',
              content: 'Some error occurred creating the new map. File a support ticket if this persists.'
            });
          } else {
            messageApi.open({
              type: 'success',
              content: 'Made new map!'
            });
          }

          setSubmitLoading(false);
          setIsModalOpen(false);
          setForceRefresh(true);
        };

        makeMap();
      }} onCancel={() => setIsModalOpen(false)} okButtonProps={{ loading: submitLoading, disabled: !submitEnabled }} cancelButtonProps={{ disabled: submitLoading }}>
        <Form form={form} name="dynamic_rule"autoComplete="off">
          <Form.Item
            {...formItemLayout}
            name="display_name"
            label="Display Name"
            rules={[{ required: true, message: 'Please put in a name for this map' }]}
          >
            <Input placeholder="Name for this map" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="background_image"
            label="Image"
            rules={[{ required: true, message: 'Please select an image using the dropdown' }]}
          >
            <AutoComplete
              value={backgroundImage}
              options={backgroundOptions}
              onSelect={(val) => setBackgroundImage(val)}
              onSearch={(val) => setBackgroundImage(val)}
              onChange={(val) => setBackgroundImage(val)}
              placeholder="Background Image"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};