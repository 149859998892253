import { CloseOutlined, InfoCircleOutlined, InfoOutlined, SearchOutlined } from "@ant-design/icons";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { Typography, Breadcrumb, Input, Button, List, Card, Image, Col, Row, Progress, Popover, Tooltip, message } from "antd";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce, useHover } from "usehooks-ts";
import * as fuzzysort from "fuzzysort";
import { filterTitle, formatBytes } from "../../../util/filterutil";
import UploadModal from "../Operations/UploadModal";
import { DatabaseImageData, OpenMessage } from "../../../types";
import { useMainMenuState } from "../MainMenu";
import { shallow } from 'zustand/shallow'
import { useGlobalState } from "../GlobalState";

interface Props {
  session: Session
}

export default function MyUploads({ session }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const [filterImages, setFilterImages] = useState<string>();

  const addNewRef = React.useRef(null);
  const addNewHovered = useHover(addNewRef);

  const [displayImagesData, setDisplayImagesData] = useMainMenuState((state) => [state.displayImagesData, state.setDisplayImagesData], shallow);
  const [originalImagesData, setOriginalImagesData] = useMainMenuState((state) => [state.originalImagesData, state.setOriginalImagesData], shallow);
  
  const setSelectedTab = useMainMenuState((state) => state.setSelectedTab);

  const [sumFilteredFilesize, setSumFilesize] = useState(0);
  const [sumOriginalFilesize, setSumOriginalFilesize] = useState(0);

  const [uploadImageOpen, setUploadImageOpen] = useState(false);

  const [forceRefresh, setForceRefresh] = useState(false);

  const [deletePending, setDeletePending] = useState<boolean[]>([]);
  const [statsPopoverShown, setStatsPopoverShown] = useState<boolean[]>([]);

  const [msg, contextHolder] = message.useMessage();

  const deleteImage = (filename: string) => {
    const formData = new FormData();
    formData.append('deleteFilename', filename);

    supabase.functions.invoke('imagecrud', {
      body: formData
    }).then((response) => {
      if (response.error) {
        msg.error(response.error);
      } else {
        msg.success('Deleted image!');
      }
      setForceRefresh(true);
    });
  };

  useEffect(() => {
    if (originalImagesData && !forceRefresh)
      return;

    if (!session || !supabase)
      return;

    const getData = async () => {
      const {error, data} = await supabase
        .from('images')
        .select('id, created_at, file_size, width, height, file_name, token_id')
        .eq('owner_id', session.user.id);

      if (data) {
        const newImages: DatabaseImageData[] = [];
        data.forEach((image) => {
          newImages.push({
            fileName: image.file_name,
            fileSize: image.file_size,
            width: image.width,
            height: image.height,
            createdAt: image.created_at,
            imageId: image.id,
            token_id: image.token_id
          });
        });
        setOriginalImagesData(newImages);
        setForceRefresh(false);
      }
    };

    getData();
  }, [supabase, session, forceRefresh, setForceRefresh]);

  useEffect(() => {
    if (!originalImagesData)
      return;

    let result: DatabaseImageData[] = [];
    if (filterImages && filterImages.length > 0) {
      fuzzysort.cleanup();
      result = fuzzysort.go(filterImages, originalImagesData, {key:'fileName'}).map((i) => {
        return {
          ...i.obj,
          highlightTitleIndexes: (i as any)._indexes
        }
      });
    } else
      result = originalImagesData;
    setDeletePending(result.map((i) => false));
    setStatsPopoverShown(result.map((i) => false));
    setDisplayImagesData(result);

    const sumFilesize = originalImagesData.length == 0 ? 0 : originalImagesData.map((i) => i.fileSize).reduce((total, item) => total + item);
    const sumFilteredFilesize = result.length == 0 ? 0 : result.map((i) => i.fileSize).reduce((total, item) => total + item);

    setSumFilesize(sumFilteredFilesize);
    setSumOriginalFilesize(sumFilesize);
  }, [originalImagesData, setDisplayImagesData, filterImages, forceRefresh])

  interface ProperDimensions {
    width: number;
    height: number;
  }

  const resizeWithAspectRatio = (maxSize: number, data: DatabaseImageData): ProperDimensions => {
    if (data.width > data.height) {
      const factor = maxSize / data.width;

      return {
        width: maxSize,
        height: data.height * factor
      };
    } else {
      const factor = maxSize / data.height;

      return {
        width: data.width * factor,
        height: maxSize,
      }
    }
  }

  // TODO get this from somewhere.
  const maxFilesize = 5242880;
  
  return (
    <>
      {contextHolder}
      <Typography.Title level={1} style={{ margin: 0 }}>
        Maps for Tabletop
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <a onClick={() => setSelectedTab(['account'])}>User</a>,
          },
          {
            title: 'Uploads',
          },
        ]}
      />
      <div style={{ marginTop: 20, display: 'flex', marginBottom: 12 }}>
        <Input size="large" disabled={deletePending.some((b: boolean) => b)} placeholder="Filter your uploads..." prefix={<SearchOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between' }} value={filterImages} onChange={(change) => setFilterImages(change.target.value)}/>
        <Button type={addNewHovered ? "primary" : 'default'} size='large' style={{ marginLeft: 12 }} ref={addNewRef} onClick={() => setUploadImageOpen(true)}>
          Upload
        </Button>
      </div>
      <div style={{ display: 'flex'}}>
        <Typography>{filterImages && filterImages.length > 0 ? 'Filtered Uploads' : 'Total Stored'} {formatBytes(filterImages && filterImages.length > 0 ? sumFilteredFilesize : sumOriginalFilesize)} / {formatBytes(maxFilesize)}</Typography>
      </div>
      <Progress strokeColor={filterImages && filterImages.length > 0 ? '#4c4c4c' : '#1668dc'} percent={Math.round((sumOriginalFilesize / maxFilesize) * 100)} success={filterImages && filterImages.length > 0 && ({ percent: Math.round((sumFilteredFilesize / maxFilesize) * 100) })}/>
      {session ? (
        <List
        grid={{
          gutter: 16,
          xs: 3,
          sm: 3,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
        dataSource={displayImagesData ? displayImagesData.sort((a, b) => {
          if (a.token_id && !b.token_id)
            return 100;
          if (!a.token_id && b.token_id)
            return -100;
          
          if (a.fileName && b.fileName)
            return a.fileName.localeCompare(b.fileName);
          return 0;
        }) : []}
        renderItem={(item, index) => (
          <List.Item>
            <Card title={<>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{filterTitle(item.fileName, item.highlightTitleIndexes)}</Typography>
                <Popover open={(deletePending.length > index && deletePending[index]) || (statsPopoverShown.length > index && statsPopoverShown[index])} onOpenChange={(visible: boolean) => {
                  let statsPopovers = [...statsPopoverShown];
                  statsPopovers[index] = visible;
                  setStatsPopoverShown(statsPopovers);
                }} content={(<>
                  <Typography.Title level={5}>Dimensions</Typography.Title>
                  <Typography>{item.width}x{item.height}</Typography>
                  <Typography.Title level={5}>Size</Typography.Title>
                  <Typography>{formatBytes(item.fileSize)}</Typography>
                  <div style={{ position: 'absolute', top: 12, right: 12 }}>
                    <Button type="dashed" danger loading={deletePending.length > index && deletePending[index]} icon={<CloseOutlined />} onClick={() => {
                      let pending = [...deletePending];
                      pending[index] = true;
                      setDeletePending(pending);
                      deleteImage(item.fileName);
                    }}> Delete?</Button>
                  </div>
                </>)} title={'Stats'}>
                  <Button type="dashed" shape="circle" icon={<InfoOutlined />} />
                </Popover>
              </div>
            </>}>
              <div style={{ maxWidth: '100%', maxHeight: '100%' }} className="constrainChildren">
                <Image
                      width={resizeWithAspectRatio(200, item).width}
                      height={resizeWithAspectRatio(200, item).height}
                      src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${session.user.id}/${item.fileName}`}
                    />
              </div>
            </Card>
          </List.Item>
        )}
      />
      ) : <></>}
      <UploadModal forceRefresh={() => setForceRefresh(true)} isModalOpen={uploadImageOpen} setIsModalOpen={setUploadImageOpen} />
    </>
  )
}