import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { FileAddOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, FloatButton, Form, Input, Modal, Popover, Radio, Tooltip, Upload, UploadFile, message } from "antd";
import Slider, { SliderMarks } from "antd/es/slider";
import { SupabaseClient } from "@supabase/supabase-js";
import { NoticeType } from "antd/es/message/interface";
import { OpenMessage } from "../../../types";
import { useGlobalState } from "../GlobalState";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void;
  forceRefresh: () => void;
}

export default function UploadModal({ isModalOpen, setIsModalOpen, forceRefresh }: Props) {
  const supabase = useGlobalState((state) => state.supabase);

  const [fileList, setFileList] = useState<UploadFile[]>();
  
  const [submitLoading, setSubmitLoading] = useState(false);

  const [form] = Form.useForm();

  const [msg, contextHolder] = message.useMessage();

  const onFinish = () => {
    console.log(fileList);
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append('count', fileList.length.toString());
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i += 1) {
        formData.append(`image${i}`, fileList[i].originFileObj, fileList[i].fileName);
      }
    } else {
      setIsModalOpen(false);
      return;
    }

    supabase.functions.invoke('imagecrud', {
      body: formData
    }).then(({data, error}) => {
      if (error) {
        msg.error(error);
      } else if (data.error) {
        msg.error(data.error);
      } else
        msg.success(fileList.length > 0 ? 'Uploaded images!' : 'Uploaded images!');
      setFileList(undefined);
      forceRefresh();
      setSubmitLoading(false);
      setIsModalOpen(false);
    });
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (<>
    {contextHolder}
    <Modal title="Upload Image" open={submitLoading || isModalOpen} onOk={() => onFinish()} onCancel={() => setIsModalOpen(false)} okButtonProps={{ loading: submitLoading, disabled: (!fileList || fileList.length == 0) }} cancelButtonProps={{ disabled: submitLoading }}>
      <Form form={form} name="dynamic_rule" style={{ maxWidth: 600, minWidth: 450 }} autoComplete="off" onFinish={onFinish}>
        <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload listType="picture-card" accept={'image/png, image/jpeg'} multiple customRequest={(options) => { options.onSuccess(null) }} fileList={fileList} onChange={({ fileList }) => setFileList(fileList)}>
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  </>);
}