import { message, SelectProps, FloatButton, Modal, Typography, Select, Divider, Space, Input, Button, Popconfirm, InputRef } from "antd";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { LuMailPlus, LuSave } from "react-icons/lu";
import { useLocalStorage } from "usehooks-ts";
import RichEditor from "../../Remirror/RichEditor";
import { useGlobalState } from "../GlobalState";
import {v4 as uuidv4} from 'uuid';


const templatesKey = 'announcement-templates';

export default function AnnouncementsCreation() {
  const userData = useGlobalState((state) => state.userData);
  const supabase = useGlobalState((state) => state.supabase);

  const [newModalOpen, setNewModalOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetEditor, setResetEditor] = useState<boolean>(true);
  const [writingState, setWritingState] = useState<string>('');
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [msg, contextHolder] = message.useMessage();
  const [existingTagOptions, setExistingTagOptions] = useState<SelectProps['options']>([]);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [localSavedTemplates, setLocalSavedTemplates] = useLocalStorage<{[key: string]: string}>(templatesKey, {});
  const [loadTemplateShowing, setLoadTemplateShowing] = useState<string>(null);

  const inputRef = useRef<InputRef>(null);

  const floatButtons = userData?.is_admin ? 
  (<>
    <FloatButton icon={<LuMailPlus />} onClick={() => setNewModalOpen(true)} />
  </>) : (<></>);

  const tryClose = () => {
    if (submitting)
      return;
    setNewModalOpen(false);
  };

  useEffect(() => {
    if (!newModalOpen) {
      setLoadTemplateShowing(null);
      setWritingState('');
      setResetEditor(true);
    } else {
      const newOptions: SelectProps['options'] = [
        {
          value: 'Critical',
          label: 'Critical'
        }
      ];

      var arrayOptions = JSON.parse(window.localStorage.getItem('announcement-tag-options') ?? '[]');
      arrayOptions.forEach((item: {value: string, label: string}) => {
        newOptions.push({
          value: item['value'],
          label: item['label']
        });
      });

      setExistingTagOptions(newOptions);
      setChosenTags([]);
    }
  }, [newModalOpen]);

  const submitCallback = useCallback(() => {
    setSubmitting(true);
    supabase.from('announcements')
      .upsert({
        id: uuidv4(),
        text: writingState,
        tags: chosenTags.join(','),
      })
      .then((response) => {
        if (response.error)
          msg.error(response.error.message, 5);
        else
          msg.success('Announcement posted!')

        window.localStorage.setItem('announcement-tag-options', JSON.stringify(chosenTags.map((tag) => ({value: tag, label: tag}))));

        setSubmitting(false);
        setNewModalOpen(false);
      });
  }, [writingState, chosenTags]);

  const addNewAnnounceModal = (
    <Modal
      centered
      open={newModalOpen}
      onCancel={() => tryClose()}
      afterClose={() => tryClose()}
      onOk={submitCallback}
      cancelButtonProps={{loading: submitting}}
      okButtonProps={{loading: submitting}}
      width={640}
    >
      <Typography.Title level={3}>Create New Announcement</Typography.Title>
      <div className="remirror-theme">
        <RichEditor 
          stateJson={writingState}
          changeStateJson={(ne) => {
            setWritingState(ne);
          }}
          reset={resetEditor}
          setReset={setResetEditor}
          editable={true}
        >          
          <Select
            mode="tags"
            style={{ width: '100%', marginBottom: '4px' }}
            placeholder="Search for or create new tags"
            onChange={(value, option) => {
              setChosenTags(value);
            }}
            options={existingTagOptions}
            value={chosenTags}
          />
        </RichEditor>
      </div>
      <Select
        style={{ width: 300, marginTop: '8px' }}
        placeholder="Load a saved Template"
        value={loadTemplateShowing}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder="Name"
                ref={inputRef}
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button type="text" icon={<LuSave />} onClick={(e) => {
                setLocalSavedTemplates({
                  ...localSavedTemplates,
                  [newTemplateName]: writingState
                });
                setNewTemplateName('');
              }}>
                Save New Template
              </Button>
            </Space>
          </>
        )}
        onChange={(val, option) => {
          setWritingState(localSavedTemplates[val]);
          setResetEditor(true);
          setLoadTemplateShowing(val);
        }}
        options={Object.entries(localSavedTemplates).map((item) => {
          const label = (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            {item[0]}
            
            <Popconfirm
              title="Delete this template?"
              description="Are you sure you want to delete this template?"
              onConfirm={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const localSaved = {...localSavedTemplates};
                delete localSaved[item[0]];
                setLocalSavedTemplates(localSaved);
              }}
              onCancel={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              okText='Yes'
              cancelText='No'
            >
              <Button type="text" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
                X
              </Button>
            </Popconfirm>
          </div>);

          return {label: label, value: item[0]};
        })}
      />
    </Modal>
  )

  return (
    <>
      {contextHolder}
      {floatButtons}
      {addNewAnnounceModal}
    </>
  )
};