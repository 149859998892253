import React from "react";
import { MdCloudDownload, MdGeneratingTokens, MdMap, MdOutlineDescription, MdOutlineFormatSize } from "react-icons/md";
import CommandTemplate, { CommandFunctionParameters } from "../CommandTemplate";
import {IoIosColorPalette, IoMdCheckmark} from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { RiText } from "react-icons/ri";
import { GrMap, GrMapLocation, GrStatusUnknown } from "react-icons/gr";
import { TbMapPinPlus } from "react-icons/tb";
import {FaCheckCircle, FaCopy, FaEdit, FaFile, FaLink, FaLock, FaSave, FaTrashAlt} from "react-icons/fa";
import {BiRectangle, BiSolidHide, BiSolidShow} from "react-icons/bi";
import { GiConcentrationOrb, GiPirateGrave } from "react-icons/gi";
import { HiOutlineStatusOnline } from "react-icons/hi";
import {DatabaseMap, DatabaseMapPoi, SvgPathDrawSettings} from '../../../../types';
import { useMainMenuState } from "../../../Menu/MainMenu";
import { shallow } from "zustand/shallow";
import {v4 as uuidv4} from 'uuid';
import { IoMapOutline } from "react-icons/io5";
import { LuEraser, LuGrab, LuMap, LuMapPin, LuMinusCircle, LuMove, LuPlusCircle } from "react-icons/lu";
import { Coordinate } from "ol/coordinate";
import {BaseStatuses, toggleStatus} from "../../../../util/basestatuses";
import * as Icons from "react-game-icons-auto";
import chroma from "chroma-js";
import {MaxFontSizePois} from "../../../../util/config";
import {ColorPicker} from "antd";
import {NormalDrawingColors} from "../../MapDisplay";
import {CommandFunctionProps} from "@remirror/pm";

export default [
  {
    icon: <LuMove />,
    text: "Move POI",
    keywords: 'move,poi',
    category: 'POI',

    perform: (props: CommandFunctionParameters) => {
      props.setForceMapMovePoi(props.mouseOverFeature);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <MdGeneratingTokens />,
    text: 'Resize / scale POI',
    keywords: 'resize,poi,scale',
    category: 'POI',

    nestedPlaceholder: "+- 2.",
    nestedBreadcrumbs: ['Map', 'POI / Resize'],
    nestedTemplates: [
      {
        icon: <LuPlusCircle />,
        text: 'Increase Font Size',
        keywords: '',
        category: '',
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
          const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
          if (index >= 0) {
            const inputVal = props.currentText.current;
            
            const newFontScaler = parseInt(newMapData.pois[index].font_override ?? '84', 10) + (inputVal ? parseInt(inputVal) : 2);
            const newValue = Math.max(1, Math.min(newFontScaler, MaxFontSizePois));

            const newPoi = {
              ...newMapData.pois[index],
              font_override: `${newValue}px serif`
            };
            newMapData.pois[index] = newPoi;
            props.setLastPoiFontSize(newPoi.font_override);
            props.setMouseOverFeature(newPoi);
            props.setEditingMapData(newMapData);
            props.setOpen(2);
          }
        },
      },
      {
        icon: <LuMinusCircle />,
        text: 'Decrease Font Size',
        keywords: '',
        category: '',
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
          const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
          if (index >= 0) {
            const inputVal = props.currentText.current;
            
            const newFontScaler = parseInt(newMapData.pois[index].font_override ?? '84', 10) - (inputVal ? parseInt(inputVal) : 2);
            console.log(newMapData.pois[index].font_override, newFontScaler);
            const newValue = Math.max(1, Math.min(newFontScaler, MaxFontSizePois));

            const newPoi = {
              ...newMapData.pois[index],
              font_override: `${newValue}px serif`
            };
            props.setLastPoiFontSize(newPoi.font_override);
            newMapData.pois[index] = newPoi;
            props.setMouseOverFeature(newPoi);
            props.setEditingMapData(newMapData);
            props.setOpen(2);
          }
        }
      },
      {
        icon: <TiCancel />,
        text: 'Done',
        keywords: '',
        category: '',
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      },
    ] as CommandTemplate[],

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <FaCopy />,
    text: "Duplicate POI",
    keywords: 'copy,duplicate,poi',
    category: 'POI',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
      const copyPoi = structuredClone(props.mouseOverFeature) as DatabaseMapPoi;
      copyPoi.poi_id = uuidv4();

      newMapData.pois.push(copyPoi);
      props.setEditingMapData(newMapData);
      
      props.setForceMapMovePoi(copyPoi);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <LuMove />,
    text: "Move token",
    keywords: 'move,token',
    category: 'Token',

    perform: (props: CommandFunctionParameters) => {
      props.setForceMapMovePoi(props.mouseOverFeature);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  // Token size for grid tokens
  {
    icon: <MdOutlineFormatSize />,
    text: 'Change token size',
    keywords: 'size,token',
    category: 'Token',

    nestedPlaceholder: 'How big in tiles? (1-20)',
    nestedBreadcrumbs: ['Map', 'Token / Size'],
    nestedTemplates: [
      {
        icon: <IoMdCheckmark />,
        text: 'Confirm Change',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          const inputVal = props.currentText.current;
          const match = inputVal.match(/^([0-1]?[0-9]|20)$/);
          if (!match) {
            props.messageAPI.error('Please provide a whole number 1-20');
            props.setOpen(2);
          } else {
            props.messageAPI.success(`Changed token size to ${inputVal}`);

            const newMapData = structuredClone(props.editingMapData);
            const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
            if (index >= 0) {
              let newPoi = {
                ...props.mouseOverFeature,
                font_override: `${parseInt(inputVal) * ((props.editingMapData.grid_line_spacing ?? 100) / 250)}`
              } as DatabaseMapPoi;

              // Now also reposition the tile to fit on the grid.
              const width_grid = props.editingMapData.grid_line_spacing ?? 100;
              const paddingLeft = props.editingMapData.grid_padding_left ?? 0;
              const paddingTop = props.editingMapData.grid_padding_top?? 0;

              const basedUpon = 250;

              const multiplierIncrement = width_grid / basedUpon;

              let currentMultiplier = multiplierIncrement;
              if (newPoi.font_override)
                currentMultiplier = parseFloat(newPoi.font_override);

              let currentScaleFactor = currentMultiplier / multiplierIncrement;

              let gridX = (newPoi.coordinate_x - paddingLeft) / (width_grid * currentScaleFactor);
              let gridY = (newPoi.coordinate_y + paddingTop) / (width_grid * currentScaleFactor);
      
              gridX = Math.floor(gridX * currentScaleFactor) / currentScaleFactor;
              gridY = Math.floor(gridY * currentScaleFactor) / currentScaleFactor;
      
              const newCoords = [(gridX + 0.5) * (width_grid * currentScaleFactor) + paddingLeft, (gridY + 0.5) * (width_grid * currentScaleFactor) - paddingTop];

              newPoi = {
                ...newPoi,
                coordinate_x: newCoords[0],
                coordinate_y: newCoords[1],
              };

              newMapData.pois[index] = newPoi;
            }
            props.setEditingMapData(newMapData);
          }
        }
      },
      {
        icon: <LuPlusCircle />,
        text: 'Up 1 Tile Size',
        keywords: '',
        category: '',
        alwaysShow: true,
        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData);
          const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
          if (index >= 0) {
            console.log(newMapData.pois[index].font_override);
            // Now also reposition the tile to fit on the grid.
            const width_grid = props.editingMapData.grid_line_spacing ?? 100;
            const paddingLeft = props.editingMapData.grid_padding_left ?? 0;
            const paddingTop = props.editingMapData.grid_padding_top?? 0;

            const basedUpon = 250;

            const multiplierIncrement = width_grid / basedUpon;

            let currentMultiplier = multiplierIncrement;
            if (newMapData.pois[index].font_override)
              currentMultiplier = parseFloat(newMapData.pois[index].font_override);

            let currentScaleFactor = currentMultiplier / multiplierIncrement;

            currentScaleFactor += 1;

            if (currentScaleFactor > 20) {
              props.messageAPI.error('Cannot reduce the token size less than 1 tile');
              props.setOpen(2);
              return;
            }
            
            props.messageAPI.success(`Changed token size to ${currentScaleFactor.toFixed(0)}`);

            let gridX = (newMapData.pois[index].coordinate_x - paddingLeft) / (width_grid * currentScaleFactor);
            let gridY = (newMapData.pois[index].coordinate_y + paddingTop) / (width_grid * currentScaleFactor);
    
            gridX = Math.floor(gridX * currentScaleFactor) / currentScaleFactor;
            gridY = Math.floor(gridY * currentScaleFactor) / currentScaleFactor;
    
            const newCoords = [(gridX + 0.5) * (width_grid * currentScaleFactor) + paddingLeft, (gridY + 0.5) * (width_grid * currentScaleFactor) - paddingTop];

            const newPoi = {
              ...newMapData.pois[index],
              coordinate_x: newCoords[0],
              coordinate_y: newCoords[1],
              font_override: `${currentScaleFactor * multiplierIncrement}`
            };

            newMapData.pois[index] = newPoi;
          }
          props.setEditingMapData(newMapData);
          props.setOpen(2);
        }
      },
      {
        icon: <LuMinusCircle />,
        text: 'Down 1 Tile Size',
        keywords: '',
        category: '',
        alwaysShow: true,
        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData);
          const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
          if (index >= 0) {
            // Now also reposition the tile to fit on the grid.
            const width_grid = props.editingMapData.grid_line_spacing ?? 100;
            const paddingLeft = props.editingMapData.grid_padding_left ?? 0;
            const paddingTop = props.editingMapData.grid_padding_top ?? 0;
            const lineWidth = props.editingMapData.grid_line_width ?? 2;

            const basedUpon = 250;

            const multiplierIncrement = width_grid / basedUpon;

            let currentMultiplier = multiplierIncrement;
            if (newMapData.pois[index].font_override)
              currentMultiplier = parseFloat(newMapData.pois[index].font_override);

            let currentScaleFactor = currentMultiplier / multiplierIncrement;

            currentScaleFactor -= 1;

            if (currentScaleFactor < 1) {
              props.messageAPI.error('Cannot reduce the token size less than 1 tile');
              props.setOpen(2);
              return;
            }

            props.messageAPI.success(`Changed token size to ${currentScaleFactor.toFixed(0)}`);

            let gridX = (newMapData.pois[index].coordinate_x - paddingLeft) / (width_grid * currentScaleFactor);
            let gridY = (newMapData.pois[index].coordinate_y + paddingTop) / (width_grid * currentScaleFactor);
    
            gridX = Math.floor(gridX * currentScaleFactor) / currentScaleFactor;
            gridY = Math.floor(gridY * currentScaleFactor) / currentScaleFactor;
    
            const newCoords = [(gridX + 0.5) * (width_grid * currentScaleFactor) + paddingLeft, (gridY + 0.5) * (width_grid * currentScaleFactor) - paddingTop];

            const newPoi = {
              ...newMapData.pois[index],
              coordinate_x: newCoords[0],
              coordinate_y: newCoords[1],
              font_override: `${currentScaleFactor * multiplierIncrement}`
            };

            newMapData.pois[index] = newPoi;
          }
          props.setEditingMapData(newMapData);
          props.setOpen(2);
        }
      },
      {
        icon: <TiCancel />,
        text: 'Cancel Change',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      },
    ] as CommandTemplate[],

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      if (!props.editingMapData || !props.editingMapData.has_grid)
        return false;

      return true;
    }
  },
  // Remove token that's moused over.
  {
    icon: <FaTrashAlt />,
    text: 'Delete this token',
    keywords: 'delete,token',
    category: 'Token',

    nestedPlaceholder: 'Are you sure?',
    nestedBreadcrumbs: ['Map', 'Token / Delete'],
    nestedTemplates: [
      {
        icon: <IoMdCheckmark />,
        text: 'Yes',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
          newMapData.pois.splice(newMapData.pois.findIndex((poi) => poi.poi_id == props.mouseOverFeature.poi_id), 1);
          props.setEditingMapData(newMapData);
        }
      },
      {
        icon: <TiCancel />,
        text: 'Cancel Change',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      },
    ] as CommandTemplate[],

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  // Remove poi that's moused over.
  {
    icon: <FaTrashAlt />,
    text: 'Delete this POI',
    keywords: 'delete,poi',
    category: 'POI',

    nestedPlaceholder: 'Are you sure?',
    nestedBreadcrumbs: ['Map', 'POI / Delete'],
    nestedTemplates: [
      {
        icon: <IoMdCheckmark />,
        text: 'Yes',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
          newMapData.pois.splice(newMapData.pois.findIndex((poi) => poi.poi_id == props.mouseOverFeature.poi_id), 1);
          props.setEditingMapData(newMapData);
        }
      },
      {
        icon: <TiCancel />,
        text: 'Cancel Change',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      },
    ] as CommandTemplate[],

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  // Find a token
  {
    icon: <LuMap />,
    text: 'Go to / find',
    keywords: 'go to,find,token',
    category: 'Misc',

    nestedPlaceholder: 'Which thing?',
    nestedBreadcrumbs: ['Map', 'Misc / Go To'],
    nestedTemplates: (props: CommandFunctionParameters) => {
      if (!props.editingMapData || !props.editingMapData.pois)
        return [] as CommandTemplate[];

      const allTokens = props.gameTokens ?? [];

      const performZoom = (props: CommandFunctionParameters, toPoi: DatabaseMapPoi) => {
        const duration = 1000;
        const zoom = props.map.getView().getZoom();
        let parts = 2;
        let called = false;
        function callback(complete: any) {
          --parts;
          if (called) {
            return;
          }
          if (parts === 0 || !complete) {
            called = true;
          }
        }
        props.map.getView().animate(
          {
            center: [toPoi.coordinate_x, toPoi.coordinate_y],
            duration: duration,
          },
          callback
        );
        props.map.getView().animate(
          {
            zoom: zoom - 1,
            duration: duration / 2,
          },
          {
            zoom: toPoi.find_zoom_amount,
            duration: duration / 2,
          },
          callback
        );
      };

      const zoomOutChangeMap = (props: CommandFunctionParameters, toPoi?: DatabaseMapPoi, toMap?: string) => {
        const duration = 500;
        const zoom = props.map.getView().getZoom();
        props.map.getView().animate(
          {
            zoom: zoom - 1,
            duration: duration,
          },
          () => {
            if (toMap) {
              props.setEditingMapId(toMap);
              props.setEditingMapData(undefined);
              props.setMapReadyForDisplay(false);
            } else if (toPoi) {
              props.setCurrentZoomFromOtherMap({
                toCoordinate: [toPoi.coordinate_x, toPoi.coordinate_y],
                map_id: toPoi.map_id,
                zoom: toPoi.find_zoom_amount,
              });
              props.setEditingMapId(toPoi.map_id);
              props.setEditingMapData(undefined);
              props.setMapReadyForDisplay(false);
            }
          }
        );
        props.map.getView().animate(
          {
            center: [props.editingMapData.projection_x / 2, props.editingMapData.projection_y / 2],
            duration: duration,
          },
          () => {}
        );
      }

      // Always use the most up to date (from GM) version of our current map, and then the server cached pois for the other maps.
      // For other maps, don't include ones that are images (tokens).
      let allPois = [
        ...props.editingMapData.pois.filter((poi) => !poi.hide_in_goto && (props.amGM || !(poi.hidden))),
        ...props.allDatabasePois.filter((poi) => !poi.hide_in_goto && (poi.map_id !== props.editingMapData.map_id && !poi.is_image && (props.amGM || !poi.hidden)))
      ];

      // We want to see what maps are available to be linked to directly, and add these at the end except for the map we are currently on.
      const linksToOtherMaps = props.amGM ? new Set(props.allDatabaseMaps.map((m) => m.map_id)) : new Set(allPois.filter((poi) => poi.links_to_map && poi.links_to_map !== props.editingMapData.map_id).map((poi) => poi.links_to_map));

      // For display, filter out pois that are only links, we'll handle these with the links to other maps variable.
      allPois = allPois.filter((poi) => !(poi.links_to_map && !poi.has_description));

      const mappedFeatures = allPois.map((poi) => {
        if (poi.is_image) {
          if (poi.map_text.startsWith('svg')) {
            const svgSettings: SvgPathDrawSettings = JSON.parse(poi.map_text.substring(3)) as SvgPathDrawSettings;
            
            return ({
              icon: (
                <div style={{
                  borderRadius: '100px',
                  backgroundColor: svgSettings.background_color ?? '#000',
                  border: `1px solid ${svgSettings.border_color}`,
                  width: '24px',
                  height: '24px',
                  marginRight: '12px',
                  overflow: 'hidden',
                  cursor: 'pointer'
                }}>
                  {/*@ts-ignore*/}
                  {Icons[svgSettings.iconName]({color: svgSettings.foreground_color ?? '#fff'})}
                  {svgSettings.extraIdentifier ? (
                    <div style={{
                      position: 'absolute',
                      bottom: '-4px',
                      left: '21px',
                      color: chroma(svgSettings.border_color).darken(1).hex(),
                      fontWeight: 'bold',
                      fontSize: '14px'
                    }}>
                      {svgSettings.extraIdentifier}
                    </div>
                  ) : (<></>)}
                </div>
              ),
              text: `${svgSettings.iconName}${svgSettings.extraIdentifier ? ` ${svgSettings.extraIdentifier}` : ''}`,
              category: props.allDatabaseMaps.find((map) => map.map_id == poi.map_id).display_name,
              keywords: 'svg',
              perform: (props: CommandFunctionParameters) => {
                if (poi.map_id == props.editingMapData.map_id)
                  performZoom(props, poi);
                else
                  zoomOutChangeMap(props, poi);
              },
            } as CommandTemplate);
          }

          const matchText = allTokens.find((t) => t.background_image == poi.map_text)?.display_name ?? poi.map_text;
          return ({
            icon: <img
              src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${props.editingMapData.owner_id}/${poi.map_text}`}
              draggable={false} style={{maxWidth: 24, maxHeight: 24, marginRight: 12}}/>,
            text: matchText,
            category: props.allDatabaseMaps.find((map) => map.map_id == poi.map_id).display_name,
            keywords: matchText,
            perform: (props: CommandFunctionParameters) => {
              if (poi.map_id == props.editingMapData.map_id)
                performZoom(props, poi);
              else
                zoomOutChangeMap(props, poi);
            },
          } as CommandTemplate);
        } else {
          console.log(props.allDatabaseMaps, poi.map_id, poi.poi_id);
          return ({
            icon: <LuMapPin />,
            text: poi.map_text,
            category: props.allDatabaseMaps.find((map) => map.map_id == poi.map_id).display_name,
            keywords: poi.desc_text,
            perform: (props: CommandFunctionParameters) => {
              if (poi.map_id == props.editingMapData.map_id)
                performZoom(props, poi);
              else
                zoomOutChangeMap(props, poi);
            },
          } as CommandTemplate);
        }
      });

      mappedFeatures.push(...[...linksToOtherMaps].map((map_id) => ({
        category: 'Maps',
        icon: <img src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${props.editingMapData.owner_id}/${props.allDatabaseMaps.find((map) => map.map_id == map_id).background_image}`} draggable={false} style={{ maxWidth: 24, maxHeight: 24, marginRight: 12 }} />,
        text: props.allDatabaseMaps.find((map) => map.map_id == map_id).display_name,
        keywords: '',
        perform: (props: CommandFunctionParameters) => {
          zoomOutChangeMap(props, undefined, map_id);
        },
      } as CommandTemplate)));

      return mappedFeatures;
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (props.mouseOverFeature && !props.isFullMenu)
        return false;

      return true;
    },
  },
  // Add a feature/poi at a position
  {
    icon: <MdGeneratingTokens />,
    text: 'Add POI here',
    keywords: 'add,poi,here',
    category: 'POI',

    nestedPlaceholder: "What's the name for this POI?",
    nestedBreadcrumbs: ['Map', 'POI / Add'],
    nestedTemplates: [
      {
        icon: <TbMapPinPlus />,
        text: 'Confirm Add',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          const inputVal = props.currentText.current;
          if (inputVal.length == 0) {
            props.messageAPI.error('Please provide a name for your POI');
            props.setOpen(2);
          } else {
            props.messageAPI.success(`Added a new POI!`);

            const positionToCoordinate = props.map.getCoordinateFromPixel([props.mousePosition[0], props.mousePosition[1]]);

            const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
            
            const newPoi = {
              map_text: inputVal,
              desc_text: "",
              desc_title: inputVal,
              coordinate_x: positionToCoordinate[0],
              coordinate_y: positionToCoordinate[1],
              poi_id: uuidv4(),
              circle_clickbox: false,
              owner_id: newMapData.owner_id,
              map_id: newMapData.map_id,
            } as DatabaseMapPoi;
            
            if (props.lastPoiFontSize)
              newPoi.font_override = props.lastPoiFontSize;
            
            newMapData.pois.push(newPoi);
            props.setEditingMapData(newMapData);
          }
        }
      },
      {
        icon: <TiCancel />,
        text: 'Cancel Change',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      },
    ] as CommandTemplate[],

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      return true;
    }
  },
  {
    icon: <FaEdit />,
    text: 'Edit this POI Text',
    keywords: 'edit,poi,here',
    category: 'POI',

    nestedPlaceholder: "Change the name for this POI to?",
    nestedBreadcrumbs: ['Map', 'POI / Edit'],
    perform: (props: CommandFunctionParameters) => {
      props.setInput(props.mouseOverFeature.map_text);
    },
    nestedTemplates: [
      {
        icon: <FaCheckCircle />,
        text: 'Confirm Change',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          const inputVal = props.currentText.current;
          if (inputVal.length == 0) {
            props.messageAPI.error('Please provide a name for your POI');
            props.setOpen(2);
          } else {
            props.messageAPI.success(`Edited POI!`);

            const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
            const thisPoiIndex = newMapData.pois.findIndex((poi) => poi.poi_id == props.mouseOverFeature.poi_id);
            newMapData.pois[thisPoiIndex] = {
              ...newMapData.pois[thisPoiIndex],
              map_text: inputVal,
            };
            props.setEditingMapData(newMapData);
          }
        }
      },
      {
        icon: <TiCancel />,
        text: 'Cancel Change',
        keywords: '',
        category: '',
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      },
    ] as CommandTemplate[],

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;
      
      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <IoIosColorPalette />,
    text: 'Change this POI color',
    keywords: 'change,poi,color',
    category: 'POI',
    
    perform: (props: CommandFunctionParameters) => {
      console.log(props.mousePosition);
      props.setPoiColorModalOptions({
        poi_id: props.mouseOverFeature.poi_id,
        mousePosition: props.mousePosition
      })
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <MdOutlineDescription />,
    text: 'Toggle Description',
    keywords: 'description',
    category: 'POI',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
      const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
      if (index >= 0) {
        const newPoi = {
          ...newMapData.pois[index],
          has_description: !newMapData.pois[index].has_description
        } as DatabaseMapPoi;
        newMapData.pois[index] = newPoi;
        props.setMouseOverFeature(newPoi);
        props.setEditingMapData(newMapData);
      }
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <FaLock />,
    text: 'Toggle Lock Poi',
    keywords: 'lock',
    category: 'POI',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
      const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
      if (index >= 0) {
        const newPoi = {
          ...newMapData.pois[index],
          locked: !newMapData.pois[index].locked
        } as DatabaseMapPoi;
        newMapData.pois[index] = newPoi;
        props.setMouseOverFeature(newPoi);
        props.setEditingMapData(newMapData);
      }
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <BiSolidHide />,
    text: 'Toggle Hide Poi',
    keywords: 'hide',
    category: 'POI',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
      const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
      if (index >= 0) {
        const newPoi = {
          ...newMapData.pois[index],
          hidden: !newMapData.pois[index].hidden
        } as DatabaseMapPoi;
        newMapData.pois[index] = newPoi;
        props.setMouseOverFeature(newPoi);
        props.setEditingMapData(newMapData);
      }
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <BiSolidHide />,
    text: 'Mark POI as unfindable',
    keywords: 'unfindable',
    category: 'POI',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
      const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
      if (index >= 0) {
        const newPoi = {
          ...newMapData.pois[index],
          hide_in_goto: true
        } as DatabaseMapPoi;
        newMapData.pois[index] = newPoi;
        props.setMouseOverFeature(newPoi);
        props.setEditingMapData(newMapData);
      }
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;
      
      if (props.mouseOverFeature.hide_in_goto)
        return false;

      return true;
    }
  },
  {
    icon: <BiSolidShow />,
    text: 'Mark POI as findable',
    keywords: 'unfindable',
    category: 'POI',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
      const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
      if (index >= 0) {
        const newPoi = {
          ...newMapData.pois[index],
          hide_in_goto: false
        } as DatabaseMapPoi;
        newMapData.pois[index] = newPoi;
        props.setMouseOverFeature(newPoi);
        props.setEditingMapData(newMapData);
      }
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;
      
      if (!props.mouseOverFeature.hide_in_goto)
        return false;

      return true;
    }
  },
  // Link a feature to another map
  {
    icon: <FaLink />,
    text: 'Link to Map',
    keywords: 'map,link',
    category: 'POI',

    nestedPlaceholder: "Which map?",
    nestedBreadcrumbs: ['Map', 'POI / Link Map'],
    nestedTemplates: (props: CommandFunctionParameters) => {
      if (!props.editingMapData || !props.editingMapData.pois)
        return [] as CommandTemplate[];

      const allTokens = props.gameTokens ?? [];

      // We want to see what maps are available to be linked to directly, and add these at the end except for the map we are currently on.
      const linksToOtherMaps = [...new Set(props.allDatabaseMaps.map((m) => m.map_id))];

      let mappedFeatures = linksToOtherMaps.map((map_id) => ({
        category: 'Maps',
        icon: <img src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${props.editingMapData.owner_id}/${props.allDatabaseMaps.find((map) => map.map_id == map_id).background_image}`} draggable={false} style={{ maxWidth: 24, maxHeight: 24, marginRight: 12 }} />,
        text: props.allDatabaseMaps.find((map) => map.map_id == map_id).display_name,
        keywords: '',
        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
          const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
          if (index >= 0) {
            const newPoi = {
              ...newMapData.pois[index],
              links_to_map: map_id,
            } as DatabaseMapPoi;
            newMapData.pois[index] = newPoi;
            props.setMouseOverFeature(newPoi);
            props.setEditingMapData(newMapData);
          }
        },
      } as CommandTemplate));

      mappedFeatures = [{
        category: 'Maps',
        icon: <TiCancel />,
        text: 'Remove Link',
        keywords: 'remove,cancel',
        shouldInclude: (props: CommandFunctionParameters) => {
          return props.mouseOverFeature.links_to_map !== undefined && props.mouseOverFeature.links_to_map !== null;
        },
        perform: (props: CommandFunctionParameters) => {
          const newMapData = structuredClone(props.editingMapData) as DatabaseMap;
          const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == props.mouseOverFeature.poi_id);
          if (index >= 0) {
            const newPoi = {
              ...newMapData.pois[index],
              links_to_map: undefined,
            } as DatabaseMapPoi;
            newMapData.pois[index] = newPoi;
            props.setMouseOverFeature(newPoi);
            props.setEditingMapData(newMapData);
          }
        }
      } as CommandTemplate, ...mappedFeatures];

      return mappedFeatures;
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (props.isFullMenu)
        return false;

      if (props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <LuEraser />,
    text: "Clear all tokens",
    keywords: 'clear,token',
    category: 'Token',

    perform: (props: CommandFunctionParameters) => {
      props.setEditingMapData({
        ...props.editingMapData,
        pois: props.editingMapData.pois?.filter((poi) => !poi.is_image) ?? [],
      });
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (props.editingMapData.pois && props.editingMapData.pois.filter((poi) => poi.is_image).length > 0)
        return true; 

      return false;
    }
  },
  {
    icon: <HiOutlineStatusOnline />,
    text: 'Token Status',
    keywords: 'status',
    category: 'Token',
    priority: 100,

    nestedPlaceholder: 'Toggle Statuses Below',
    nestedBreadcrumbs: ['Map', 'Token / Status'],
    nestedTemplates: (props: CommandFunctionParameters) => {
      return BaseStatuses.map((base) => ({
        icon: base.color ? <base.icon color={base.color}/> : <base.icon/>,
        text: `Toggle ${base.status}`,
        keywords: '',
        category: '',

        perform: (props: CommandFunctionParameters) => toggleStatus(props, base.status)
      } as CommandTemplate));
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverFeature)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      return true;
    }
  },
  {
    icon: <MdCloudDownload />,
    text: 'Load tokens from saved layout',
    keywords: 'load,token',
    category: 'Token Layouts',

    nestedPlaceholder: 'Select a layout to load',
    nestedBreadcrumbs: ['Map', 'Token / Load Layout'],
    nestedTemplates: (props: CommandFunctionParameters) => {
      const toReturn = [] as CommandTemplate[];

      const existingSavedItems = JSON.parse(localStorage.getItem('poi_layouts') || '[]') as any[];

      for (let i = 0; i < existingSavedItems.length; i += 1) {
        const thisEntry = existingSavedItems[i];

        toReturn.push({
          icon: <FaFile />,
          text: thisEntry['name'],
          keywords: thisEntry['name'],
          category: 'Saved',
          perform: (props: CommandFunctionParameters) => {
            const newMapData = structuredClone(props.editingMapData) as DatabaseMap;

            for (let itemIndex = 0; itemIndex < thisEntry['items'].length; itemIndex += 1) {
              const thisItem = thisEntry['items'][itemIndex];

              // Check if we have this token on the map already
              const foundIndex = newMapData.pois.findIndex((poi) => {
                return poi.map_text == thisItem.map_text && poi.is_image;
              });

              if (foundIndex >= 0) {
                newMapData.pois[foundIndex] = {
                  ...newMapData.pois[foundIndex],
                  ...thisItem,
                }
              } else {
                newMapData.pois.push({
                  ...thisItem,
                  poi_id: uuidv4(),
                  map_id: newMapData.map_id,
                  owner_id: newMapData.owner_id,
                  circle_clickbox: false,
                  find_zoom_amount: 4,
                } as DatabaseMapPoi);
              }
            }

            props.setEditingMapData(newMapData);
          },
        } as CommandTemplate)
      }

      toReturn.push({
        icon: <TiCancel />,
        text: "Cancel",
        keywords: '',
        category: 'Controls',
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      });

      return toReturn;
    },
    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      const existingSavedItems = JSON.parse(localStorage.getItem('poi_layouts') || '[]') as any[];

      return existingSavedItems.length > 0;
    }
  },
  {
    icon: <FaSave />,
    text: 'Save tokens on map as layout',
    keywords: 'save,token',
    category: 'Token Layouts',

    nestedPlaceholder: 'Name for Layout?',
    nestedBreadcrumbs: ['Map', 'Token / Save Layout'],
    nestedTemplates: [
      {
        icon: <IoMdCheckmark />,
        text: "Save",
        keywords: '',
        category: '',
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          const inputVal = props.currentText.current;

          const toSave = props.editingMapData.pois.filter((item) => item.is_image).map((item) => ({
            map_text: item.map_text,
            is_image: true,
            has_description: false,
            coordinate_x: item.coordinate_x,
            coordinate_y: item.coordinate_y,
            font_override: item.font_override,
            find_zoom_amount: item.find_zoom_amount,
            extra_json_data: item.extra_json_data,
            status: item.status,
          }));

          const existingSavedItems = JSON.parse(localStorage.getItem('poi_layouts') || '[]') as any[];
          const matchingIndex = existingSavedItems.findIndex((item) => item['name'] === inputVal);
          if (matchingIndex >= 0)
            existingSavedItems[matchingIndex] = {
              name: inputVal,
              items: toSave
            };
          else
            existingSavedItems.push({
              name: inputVal,
              items: toSave
            });

          localStorage.setItem('poi_layouts', JSON.stringify(existingSavedItems));
        }
      },
      {
        icon: <TiCancel />,
        text: "Cancel",
        keywords: '',
        category: '',
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      }
    ] as CommandTemplate[],
    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;
      
      const toSave = props.editingMapData.pois.filter((item) => item.is_image).map((item) => ({
        map_text: item.map_text,
        is_image: true,
        has_description: false,
        coordinate_x: item.coordinate_x,
        coordinate_y: item.coordinate_y,
        font_override: item.font_override,
        find_zoom_amount: item.find_zoom_amount,
        extra_json_data: item.extra_json_data,
        status: item.status,
      }));

      return toSave.length > 0;
    }
  },
  {
    icon: <FaTrashAlt />,
    text: 'Delete saved token layout',
    keywords: 'delete,token,layout',
    category: 'Token Layouts',

    nestedPlaceholder: 'Select a layout to delete',
    nestedBreadcrumbs: ['Map', 'Token / Delete Layout'],
    nestedTemplates: (props: CommandFunctionParameters) => {
      const toReturn = [] as CommandTemplate[];

      const existingSavedItems = JSON.parse(localStorage.getItem('poi_layouts') || '[]') as any[];

      for (let i = 0; i < existingSavedItems.length; i += 1) {
        const thisEntry = existingSavedItems[i];

        toReturn.push({
          icon: <FaFile />,
          text: thisEntry['name'],
          keywords: thisEntry['name'],
          category: 'Saved',
          perform: (props: CommandFunctionParameters) => {
            const newList = [...existingSavedItems];
            newList.splice(i, 1);
            localStorage.setItem('poi_layouts', JSON.stringify(newList));
          },
        } as CommandTemplate)
      }

      toReturn.push({
        icon: <TiCancel />,
        text: "Cancel",
        keywords: '',
        category: 'Controls',
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      });

      return toReturn;
    },
    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      const existingSavedItems = JSON.parse(localStorage.getItem('poi_layouts') || '[]') as any[];

      return existingSavedItems.length > 0;
    }
  },
] as CommandTemplate[];