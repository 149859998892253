import { MdDoneOutline, MdSchedule } from "react-icons/md";
import CommandTemplate, { CommandFunctionParameters } from "../CommandTemplate";
import React from "react";
import {DatabaseMap, InitiativeSpot} from "../../../../types";
import {FaArrowRight, FaRunning} from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { IoMdCheckmark } from "react-icons/io";
import {BsFillSignStopFill} from "react-icons/bs";
import {FaPeopleGroup} from "react-icons/fa6";

export default [
  {
    icon: <FaRunning />,
    text: 'Approve Movement',
    keywords: 'approve',
    category: 'Initiative',

    perform: (props: CommandFunctionParameters) => {
      const newPosition = props.mouseOverRequestedMovement.path[props.mouseOverRequestedMovement.path.length - 1];
      const mapData = structuredClone(props.editingMapData) as DatabaseMap;

      const matchingPoiIndex = mapData.pois.findIndex((poi) => poi.poi_id == props.mouseOverRequestedMovement.poi_id);
      mapData.pois[matchingPoiIndex] = {
        ...mapData.pois[matchingPoiIndex],
        coordinate_x: newPosition[0],
        coordinate_y: newPosition[1],
      };
      props.setEditingMapData(mapData);

      props.setPoiCoordinateChanges({
        [props.mouseOverRequestedMovement.poi_id]: newPosition
      });
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverRequestedMovement)
        return false;

      return true;
    }
  },
  {
    icon: <BsFillSignStopFill />,
    text: 'Forbid Movement',
    keywords: 'forbid,ignore',
    category: 'Initiative',

    perform: (props: CommandFunctionParameters) => {
      props.setWorkingMoveArrows((previous) => {
        const toReturn = {...previous};
        if (props.mouseOverRequestedMovement.poi_id in toReturn)
          delete toReturn[props.mouseOverRequestedMovement.poi_id];
        return toReturn;
      })
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.mouseOverRequestedMovement)
        return false;

      return true;
    }
  },
  {
    icon: <MdSchedule />,
    text: "Start Initiative",
    keywords: 'initiative',
    category: 'Initiative',

    perform: (props: CommandFunctionParameters) => {
      const editing = props.editingMapData;

      const newItems: InitiativeSpot[] = [];
      const unsortedPlayers: InitiativeSpot[] = [];
      
      editing.pois?.forEach((poi) => {
        // Don't take text features
        if (!poi.is_image)
          return;
        if (poi.map_text.includes('PLAYER')) {
          // This is a player token

          unsortedPlayers.push({
            poi_id: poi.poi_id,
            roll: 0,
            health: 0,
            max_health: 0,
            
            reaction_used: false,
            
            movement: 6,
            max_movement: 6,
          });
          return;
        }

        const poiData = JSON.parse(poi.extra_json_data ?? '{}');

        if ('skip_initiative' in poiData || poiData["skip_initiative"] !== undefined)
          return;

        const initiativeMod = parseInt(poiData['initiative_mod'] ?? 0);

        let health = 50;
        if (poiData['max_health'])
          health = parseInt(poiData['max_health'])
        
        let movementTiles = 6;
        if (poiData['movement_tiles'])
          movementTiles = parseInt(poiData['movement_tiles']) ?? movementTiles;

        newItems.push({
          poi_id: poi.poi_id,
          roll: Math.floor(Math.random() * 20) + initiativeMod,
          health: health,
          max_health: health,
          
          movement: movementTiles,
          max_movement: movementTiles
        });
      });

      newItems.sort((a, b) => b.roll - a.roll);
      editing.initiative = {
        spots: newItems,
        current: 0,
        unsorted_players: unsortedPlayers.length > 0 ? unsortedPlayers : undefined
      };

      props.setEditingMapData(editing);
      props.setForceDrawingUpdate(true);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (props.editingMapData && props.editingMapData.initiative)
        return false;

      if (props.mouseOverFeature && !props.isFullMenu)
        return false;

      // Check that there is at least one thing to put into initiative
      if (props.editingMapData.pois) {
        for (let i = 0; i < props.editingMapData.pois.length; i++) {
          const poi = props.editingMapData.pois[i];
          if (!poi.is_image)
            continue;
          if (poi.map_text.includes('PLAYER')) {
            return true;
          }

          const poiData = JSON.parse(poi.extra_json_data ?? '{}');

          if ('skip_initiative' in poiData || poiData["skip_initiative"] !== undefined)
            continue;

          return true;
        }
      }
      
      return false;
    }
  },
  {
    icon: <FaArrowRight />,
    text: "Next Initiative",
    keywords: 'initiative',
    category: 'Initiative',

    perform: (props: CommandFunctionParameters) => {
      const editing = structuredClone(props.editingMapData) as DatabaseMap;

      editing.initiative.current += 1;
      if (editing.initiative.current >= editing.initiative.spots.length)
        editing.initiative.current = 0;

      // Continue scrolling if our next one is dead.
      while (editing.initiative.spots[editing.initiative.current].health <= 0)
      {
        editing.initiative.current += 1;
        if (editing.initiative.current >= editing.initiative.spots.length)
          editing.initiative.current = 0;
      }
      
      const newSpot = editing.initiative.spots[editing.initiative.current];
      if (newSpot.reaction_used) {
        newSpot.reaction_used = false;
        editing.initiative.spots[editing.initiative.current] = newSpot;
      }
      
      if (newSpot.max_movement) {
        newSpot.movement = newSpot.max_movement;
        editing.initiative.spots[editing.initiative.current] = newSpot;
      }

      props.setEditingMapData(editing);
      props.setForceDrawingUpdate(true);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.editingMapData || !props.editingMapData.initiative)
        return false;

      return true;
    }
  },
  {
    icon: <MdDoneOutline />,
    text: "End Initiative",
    keywords: 'initiative',
    category: 'Initiative',

    perform: (props: CommandFunctionParameters) => {
      const editing = props.editingMapData;
      editing.initiative = undefined;
      props.setEditingMapData(editing);
      props.setForceDrawingUpdate(true);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.editingMapData || !props.editingMapData.initiative)
        return false;

      return true;
    }
  },
  {
    icon: <FaPeopleGroup />,
    text: "Add new tokens to initiative",
    keywords: 'initiative',
    category: 'Initiative',

    perform: (props: CommandFunctionParameters) => {
      const editing = props.editingMapData;

      const newItems: InitiativeSpot[] = [...props.editingMapData.initiative.spots];
      const unsortedPlayers: InitiativeSpot[] = [];
      
      const allCurrentInitiativePois = props.editingMapData.initiative.spots.map((spot) => spot.poi_id);
      
      editing.pois?.forEach((poi) => {
        // Don't take text features
        if (!poi.is_image)
          return;
        
        // Don't add POIS already in initiative
        if (allCurrentInitiativePois.includes(poi.poi_id))
          return;
        
        if (poi.map_text.includes('PLAYER')) {
          // This is a player token

          unsortedPlayers.push({
            poi_id: poi.poi_id,
            roll: 0,
            health: 0,
            max_health: 0,

            reaction_used: false,

            movement: 6,
            max_movement: 6,
          });
          return;
        }

        const poiData = JSON.parse(poi.extra_json_data ?? '{}');

        if ('skip_initiative' in poiData || poiData["skip_initiative"] !== undefined)
          return;

        const initiativeMod = parseInt(poiData['initiative_mod'] ?? 0);

        let health = 50;
        if (poiData['max_health'])
          health = parseInt(poiData['max_health'])

        let movementTiles = 6;
        if (poiData['movement_tiles'])
          movementTiles = parseInt(poiData['movement_tiles']) ?? movementTiles;

        newItems.push({
          poi_id: poi.poi_id,
          roll: Math.floor(Math.random() * 20) + initiativeMod,
          health: health,
          max_health: health,

          movement: movementTiles,
          max_movement: movementTiles
        });
      });

      newItems.sort((a, b) => b.roll - a.roll);
      editing.initiative = {
        spots: newItems,
        current: 0,
        unsorted_players: unsortedPlayers.length > 0 ? unsortedPlayers : undefined
      };

      props.setEditingMapData(editing);
      props.setForceDrawingUpdate(true);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.editingMapData || !props.editingMapData.initiative)
        return false;
      
      const allPoiIds = props.editingMapData.initiative.spots.map((spot) => spot.poi_id);

      let anyMissing = false;
      for (let i = 0; i < props.editingMapData.pois.length; i++) {
        const thisPoi = props.editingMapData.pois[i];
        if (!thisPoi.is_image)
          continue;
        
        if (!allPoiIds.includes(thisPoi.poi_id)) {
          anyMissing = true;
          break;
        }
      }

      return anyMissing;
    }
  },
] as CommandTemplate[];