import { SupabaseClient, Session } from "@supabase/supabase-js";
import { Input, InputNumber, Modal, Space, Typography, message, theme } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useGlobalState } from "../GlobalState";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void,
  forceRefresh: () => void,
}

export default function JoinGameModal({ isModalOpen, setIsModalOpen, forceRefresh }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [textboxOne, setTextboxOne] = useState<string>();
  const [textboxTwo, setTextboxTwo] = useState<string>();
  const [textboxThree, setTextboxThree] = useState<number>();
  const [messageApi, contextHolder] = message.useMessage();
  
  const { token } = theme.useToken();

  useEffect(() => {
    if (!isModalOpen)
      return;

    setTextboxOne(undefined);
    setTextboxTwo(undefined);
    setTextboxThree(undefined);
  }, [isModalOpen]);

  useEffect(() => {
    if (!textboxOne)
      return;
    if (textboxOne.includes('-')) {
      const parts = textboxOne.split('-');
      if (parts.length == 3) {
        setTextboxOne(parts[0]);
        setTextboxTwo(parts[1]);
        setTextboxThree(parseInt(parts[2], 10));
      }
    }

  }, [textboxOne]);

  return (
    <>
      {contextHolder}
      <Modal centered title="Join a Game" open={isModalOpen} onCancel={() => {
        if (submitLoading)
          return;

        setIsModalOpen(false)
      }} onOk={() => {
        if (submitLoading)
          return;

        setSubmitLoading(true);

        const formData = new FormData();
        formData.append('joining', `${textboxOne}-${textboxTwo}-${textboxThree}`);

        // Find the old one 
        supabase.functions.invoke('invites', {
          body: formData
        }).then((res) => {
          console.log(res);
          let close = true;
          if (res.data.error) {
            messageApi.open({
              type: 'error',
              content: res.data.error,
            });
            close = false;
          }
          setSubmitLoading(false);

          if (close) {
            setIsModalOpen(false);
            forceRefresh();
          }
        });
      }} okText='Join' okButtonProps={{ loading: submitLoading, disabled: submitLoading || !textboxOne || !textboxTwo || textboxThree == undefined }} cancelButtonProps={{ disabled: submitLoading }}>
        <Typography style={{ color: token.colorTextSecondary, marginTop: 16 }}>Enter a game code provided by your GM below</Typography>
        <Typography style={{ color: token.colorTextTertiary, marginBottom: 8 }}>(PS: You can paste your copied code into the first box, we'll split it.)</Typography>
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Space size='middle' style={{ width: '50%', alignItems: 'center', gap: '4px', flexGrow: 0.5 }}>
            <Input value={textboxOne} onChange={(evt) => setTextboxOne(evt.target.value)} disabled={submitLoading} />
            -
            <Input value={textboxTwo} onChange={(evt) => setTextboxTwo(evt.target.value)} disabled={submitLoading}  />
            -
            <InputNumber value={textboxThree} onChange={(val) => setTextboxThree(val)} disabled={submitLoading} />
          </Space>
        </div>
      </Modal>
    </>
  )
}