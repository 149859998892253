import { Button, Modal, Popconfirm, Space, Table, Tooltip, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DatabaseGameInvite } from "../../../types";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { v4 as uuidv4 } from 'uuid';
import { hri } from 'human-readable-ids';
import { CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useGlobalState } from "../GlobalState";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void,
  gameId: string;
}

export default function GameInvitesModal({ isModalOpen, setIsModalOpen, gameId }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [hoveredTableItem, setHoveredTableItem] = useState<string>();

  const [dataSource, setDataSource] = useState<DatabaseGameInvite[]>();

  useEffect(() => {
    if (!gameId)
      return;
    
    if (!isModalOpen)
      return;

    if (!supabase)
      return;

    supabase
      .from('invites')
      .select('*')
      .eq('game_id', gameId)
      .then((res) => {
        setDataSource(res.data.map((item, index) => {
          return {
            ...item,
            key: index.toString()
          } as DatabaseGameInvite;
        }));
        setForceRefresh(false);
      });
  }, [isModalOpen, gameId, supabase, forceRefresh]);

  const revokePhrase = (codePhrase: string) => {
    if (submitLoading)
      return;

    setSubmitLoading(true);
    supabase
      .from('invites')
      .delete()
      .eq('owner_id', session.user.id)
      .eq('invite_code', codePhrase)
      .then((res) => {
        if (res.error) {
          message.open({
            type: 'error',
            content: res.error.message,
          });
        }
        setForceRefresh(true);
        setSubmitLoading(false);
      })
  }
  
  return (
    <>
      {contextHolder}
      <Modal centered title="Game Invites" open={isModalOpen} onOk={() => {
        if (submitLoading)
          return;

        setSubmitLoading(true);

        const formData = new FormData();
        formData.append('creating', hri.random());
        formData.append('gameId', gameId);
        supabase.functions.invoke('invites', {
          body: formData
        }).then((res) => {
          if (res.data.error) {
            messageApi.open({
              type: 'error',
              content: res.data.error,
            });
          }
          setForceRefresh(true);
          setSubmitLoading(false);
        });
      }} onCancel={() => {
        if (submitLoading)
          return;

        setIsModalOpen(false);
      }} okButtonProps={{ loading: submitLoading }} cancelButtonProps={{ disabled: submitLoading }} okText="Add New" cancelText={'Close'}>
        <Table dataSource={dataSource} bordered 
          columns={[
          {
            title: 'Invite Code',
            dataIndex: 'invite_code',
            key: 'code',
            render: (_, record, index) => (
               <Tooltip title="Click to copy!" popupVisible={hoveredTableItem == record.invite_code}>
                <div style={hoveredTableItem == record.invite_code ? {color: 'white'} : undefined}>
                  {record.invite_code}
                  <CopyOutlined style={{ marginLeft: 4 }}/>
                </div>
              </Tooltip>
            ),
            onCell: (record, index) => ({
              onMouseOver: (evt) => setHoveredTableItem(record.invite_code),
              onMouseLeave: (evt) => {
                if (hoveredTableItem == record.invite_code)
                  setHoveredTableItem(undefined);
              },
              onMouseDown: (evt) => {
                navigator.clipboard.writeText(record.invite_code);
              }
            })
          },
          {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created',
            render: (_, record) => (
              new Date(record.created_at).toLocaleString('en-US')
            )
          },
          {
            title: 'Accepted?',
            dataIndex: 'acceptor_id',
            key: 'accepted',
            render: (_, record) => {
              return record.acceptor_id ? <>{'Yes'}</> : <>{'No'}</>;
            }
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Space size='middle'>
                {record.acceptor_id ? (
                  <Popconfirm
                  title="Revoke this invite?"
                  description="Are you sure you want to revoke this invite code? It will stop this player from accessing the game."
                  onConfirm={() => revokePhrase(record.invite_code)}
                >
                  <a>Revoke</a>
                </Popconfirm>
                ) : (
                  <a
                    onClick={() => {
                      revokePhrase(record.invite_code);
                    }}
                  >Revoke</a>
                )}
                
              </Space>
            )
          }
        ]} />
      </Modal>
    </>
  )
}