import {Breadcrumb, Button, Card, Skeleton, Typography} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {useGlobalState} from "../GlobalState";
import {ReactNode} from 'react';
import {FaPatreon} from "react-icons/fa";
import {useHover} from "usehooks-ts";

export default function MyAccount() {
  const patreon = useGlobalState((state) => state.patreon);
  const session = useGlobalState((state) => state.session);
  const supabase = useGlobalState((state) => state.supabase);

  const manageRef = useRef(null);
  const manageRefHovered = useHover(manageRef);
  const [storageUsed, setStorageUsed] = useState<number>(undefined);

  useEffect(() => {
    if (!supabase || !session)
      return;

    supabase
      .from('images')
      .select('file_size.sum()')
      .eq('owner_id', session.user.id)
      .single()
      .then((response) => {
        if (response.error)
        {
          console.error(response.error);
          return;
        }
        
        // @ts-ignore
        const {sum} = response.data;
        setStorageUsed(sum);
      });
  }, [supabase, session]);

  const newCard = (title: ReactNode | undefined, content: ReactNode, extra?: undefined | ReactNode) => (
    <Card title={title} style={{width: 250, backgroundColor: 'rgb(31,31,31)'}} extra={extra}>
      {content}
    </Card>
  );

  const humanFileSize = (bytes: number, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }


  let patronStatus: ReactNode = 'Link Patreon for benefits';
  if (patreon) {
    if (patreon.patron_status == 'active_patron') {
      if (patreon.next_charge_date) {
        const now = new Date();
        const diff = new Date(Date.parse(patreon.next_charge_date)).getTime() - now.getTime();
        patronStatus = `Active (Renews in ${Math.round(diff / (1000 * 60 * 60 * 24))} days)`;
      } else
        patronStatus = 'Active';
    } else if (patreon.patron_status == 'former_patron') {
      patronStatus = 'Former Patron';
    } else if (patreon.patron_status == 'declined_patron') {
      patronStatus = 'Declined. Check payment?'
    }
  }

  return (
    <>
      <Typography.Title level={1} style={{margin: 0}}>
        My Account
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <a onClick={() => {}}>User</a>,
          },
        ]}
      />
      <div style={{marginTop: '12px', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 250px)', gridAutoRows: '125px', gap: '12px' }}>
        {!patreon ? (
          <>
            {newCard(undefined, <Skeleton active/>)}
          </>
        ) : (
          <>
            {newCard(
              'Patreon Status',
              patronStatus,
              <>
                <Button ref={manageRef} type={manageRefHovered ? 'primary' : 'default'} onClick={() => {
                  if (patreon.patron_status != null)
                    window.open('https://patreon.com/mapsfortabletop/membership');
                  else {
                    const clientId = 'mDIp5kbr4u7ef5lSpY_rGJ8Jxs9qrU8D40jJTPYcm9ROdyMUilwyLimBUyGeTrKF';
                    // const redirect = 'http://www.mapsfortable.top';
                    const redirect = 'http://www.localhost:8080';
                    window.location.replace(`http://www.patreon.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect}&state=${session.user.id}`);
                  }
                }}>
                  {patreon.patron_status != null ? 'Manage' : 'Link'}
                </Button>
              </>
            )}
          </>
        )}
        {storageUsed === undefined || !patreon ? (
          <>
            {newCard(undefined, <Skeleton active/>)}
          </>
        ) : (
          <>
            {
              newCard(
                'Storage Used',
                `${humanFileSize(storageUsed, false)} / ${patreon.currently_entitled_amount_cents > 500 ? 'Unlimited' : '50 MB'}`,
              )
            }
          </>
        )}
      </div>
    </>
  )
}