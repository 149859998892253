import { MailOutlined } from "@ant-design/icons";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { SupabaseClient } from "@supabase/supabase-js";
import { Typography, Breadcrumb, Input, Button, message, Alert } from 'antd';
import Paragraph from "antd/es/typography/Paragraph";
import * as React from "react";
import { useRef, useState } from "react";
import { useHover } from "usehooks-ts";
import { useMainMenuState } from "../MainMenu";
import { shallow } from "zustand/shallow";
import { useGlobalState } from "../GlobalState";

interface Props {
  setSelectedPage: (s: string) => void;
}

export default function SignIn({ setSelectedPage }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const setSession = useGlobalState((state) => state.setSession);
  const [emailAddress, setEmailAddress] = useState<string>();
  const [accessCode, setAccessCode] = useState<string>();
  const [isSent, setIsSent] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [isError, setIsError] = useState<string>();

  const addNewRef = useRef(null);
  const addNewHovered = useHover(addNewRef);

  const accessCodeRef = useRef(null);
  const accessCodeHovered = useHover(addNewRef);
  
  const setSelectedTab = useMainMenuState((state) => state.setSelectedTab);

  let content = (
    <>
      <Typography.Title level={3}>Enter your email to get a code</Typography.Title>
        <Typography style={{ textAlign: 'center' }}>The email will come from 'supabase'.<br /> Copy and paste the code here to start playing.</Typography>
        <Input size="large" placeholder="Enter your email address" prefix={<MailOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between', width: '40%', marginTop: 12 }} value={emailAddress} onChange={(change) => setEmailAddress(change.target.value)}/>
        <Button type={addNewHovered ? "primary" : 'default'} disabled={isPressed} size='large' style={{ marginTop: 12, marginBottom: 12 }} ref={addNewRef} onClick={() => {
          setIsPressed(true);
          supabase.auth.signInWithOtp({
            email: emailAddress,
            options: {
              emailRedirectTo: 'http://localhost:8080/',
            },
          }).then(({error, data}) => {
            if (error) {
              setIsError(error.message);
              setIsPressed(false);
            } else {
              setIsSent(true);
              setIsPressed(false);
            }
          })
        }}>
          Send Magic Link
        </Button>
    </>
  );

  if (isSent) {
    content = (
      <>
        <Typography.Title level={3} style={{ marginTop: 0, textAlign: 'center' }}>Paste the code you received in your email</Typography.Title>
        <Typography style={{ textAlign: 'center' }}>The email will come from 'supabase'.<br /> It may take a few seconds for the email to arrive.</Typography>
        <Input size="large" placeholder="Enter your access code" prefix={<MailOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between', width: '40%', marginTop: 12 }} value={accessCode} onChange={(change) => setAccessCode(change.target.value)}/>
        <Button type={accessCodeHovered ? "primary" : 'default'} disabled={isPressed || !accessCode || accessCode.length == 0} loading={isPressed} size='large' style={{ marginTop: 12, marginBottom: 12 }} ref={accessCodeRef} onClick={() => {
          setIsPressed(true);
          supabase.auth.verifyOtp({
            type: 'email',
            email: emailAddress,
            token: accessCode,
          }).then((response) => {
            if (response.error)
              setIsError(response.error.message);
            else {
              setSession(response.data.session);
              setSelectedPage('account');
            }

            setIsPressed(false);
          })
        }}>
          Start Playing
        </Button>
      </>
    )
  }

  return (
    <>
      <Typography.Title level={1} style={{ margin: 0 }}>
        Maps for Tabletop
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <a onClick={() => setSelectedTab(['account'])}>User</a>,
          },
          {
            title: 'Sign In',
          },
        ]}
      />
      <div style={{ marginTop: 20, display: 'flex', marginBottom: 12, flexDirection: 'column'}}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {content}
          {isSent && (
            <Alert message="Magic code sent! Check your email." type="success" showIcon />
          )}
          {isError && (
            <Alert message={isError} type="error" showIcon />
          )}
        </div>
      </div>
    </>
  )
}