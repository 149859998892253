import * as React from "react";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import { useContext, useEffect, useRef, useState } from 'react';
import { useMainMenuState } from "../MainMenu";
import { View, useGlobalState } from "../GlobalState";
import { shallow } from "zustand/shallow";
import { DatabaseGameData, DatabaseMap, DatabaseToken } from '../../../types';
import { Breadcrumb, Button, Card, Input, List, Popover, Skeleton, Typography, message, Image, Tooltip, Space, Collapse } from "antd";
import { CloseOutlined, InfoOutlined, LoginOutlined, RollbackOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { useHover, useLocalStorage } from "usehooks-ts";
import * as fuzzysort from "fuzzysort";
import { filterTitle, formatBytes } from "../../../util/filterutil";
import Map from 'ol/Map';
import CreateMapModal from "../Operations/CreateMapModal";
import CreateTokenModal from "../Operations/CreateTokenModal";

interface Props {

}

export default function GameTokens({ }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);

  const editingGameId = useGlobalState((state) => state.editingGameId);
  const [editingMapId, setEditingMapId] = useGlobalState((state) => [state.editingMapId, state.setEditingMapId], shallow);

  const [editingGameData, setEditingGameData] = useMainMenuState((state) => [state.editingGameData, state.setEditingGameData], shallow);

  const [originalGameTokens, setOriginalGameTokens] = useMainMenuState((state) => [state.originalGameTokens, state.setOriginalGameTokens], shallow);
  const [displayGameTokens, setDisplayGameTokens] = useMainMenuState((state) => [state.displayGameTokens, state.setDisplayGameTokens], shallow);

  const [forceRefresh, setForceRefresh] = useMainMenuState((state) => [state.forceRefresh, state.setForceRefresh], shallow);
  const setSelectedTab = useMainMenuState((state) => state.setSelectedTab);

  const [createTokenModalOpen, setCreateTokenModalOpen] = useState(false);

  const [filterTokens, setFilterTokens] = useState<string>();

  const [globalCollapseKeys, setGlobalCollapseKeys] = useLocalStorage<string | string[]>('open-global-tokens', []);
  const [localCollapseKeys, setLocalCollapseKeys] = useLocalStorage<string | string[]>('open-local-tokens', []);

  const addNewRef = useRef(null);
  const addNewHovered = useHover(addNewRef);

  const [msg, contextHolder] = message.useMessage();

  // This one makes sure we have game data
  useEffect(() => {
    if (!editingGameData && editingGameId && supabase && session) {
      // Send a request to try to get it
      const getData = async () => {
        const { error, data } = await supabase.from('games')
          .select('*')
          .eq('owner_id', session.user.id)
          .eq('game_id', editingGameId)
          .limit(1)
          .single();

        if (data) {
          setEditingGameData(data as DatabaseGameData);
        } else {
          msg.error('Could not find that game!');
        }
      };

      getData();
    }
  }, [editingGameId, editingGameData, setEditingGameData, supabase, session]);

  // This one makes sure we have token data
  useEffect(() => {
    if (originalGameTokens && !forceRefresh)
      return;

    if (supabase && session && session.user && editingGameId) {
      const getData = async () => {
        const { error, data } = await supabase
          .from('tokens')
          .select('*')
          .or(`is_global.eq.TRUE,and(owner_id.eq.${session.user.id},game_id.eq.${editingGameId})`);

        if (data) {
          setOriginalGameTokens(data.map((item) => ({
            ...(item as unknown as DatabaseToken)
          })));
          setForceRefresh(false);
        }
      };

      getData();
    }
  }, [editingGameId, supabase, session, setOriginalGameTokens, forceRefresh, setForceRefresh, originalGameTokens]);

  useEffect(() => {
    if (!originalGameTokens)
      return;

    let result: DatabaseToken[] = [];
    if (filterTokens && filterTokens.length > 0) {
      fuzzysort.cleanup();
      result = fuzzysort.go(filterTokens, originalGameTokens, { key: 'display_name' }).map((i) => {
        return {
          ...i.obj,
          highlightTitleIndexes: (i as any)._indexes
        }
      });
    } else
      result = originalGameTokens;
    setDisplayGameTokens(result);
  }, [originalGameTokens, setDisplayGameTokens, filterTokens, forceRefresh]);

  if (!editingGameData || !originalGameTokens) {
    return <Skeleton />;
  }

  interface ProperDimensions {
    width: number;
    height: number;
  }

  let matchFilterGlobal = displayGameTokens && displayGameTokens.length > 0 ? displayGameTokens.filter((t: DatabaseToken) => t.is_global).length : 0;
  let matchFilterLocal = displayGameTokens && displayGameTokens.length > 0 ? displayGameTokens.filter((t: DatabaseToken) => !t.is_global).length : 0;

  return (
    <>
      {contextHolder}
      <Typography.Title level={1} style={{ margin: 0 }}>
        {editingGameData ? editingGameData.display_name : 'Loading...'}
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <a onClick={() => setSelectedTab(['details'])}>Game</a>,
          },
          {
            title: 'Tokens',
          },
        ]}
      />
      <div style={{ marginTop: 20, display: 'flex', marginBottom: 12 }}>
        <Input size="large" placeholder="Filter your tokens..." prefix={<SearchOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between' }} value={filterTokens} onChange={(change) => setFilterTokens(change.target.value)} />
        <Button type={addNewHovered ? "primary" : 'default'} size='large' style={{ marginLeft: 12 }} ref={addNewRef} onClick={() => setCreateTokenModalOpen(true)}>
          Create New?
        </Button>
      </div>
      <div style={{ overflowX: 'hidden', overflowY: 'auto', paddingBottom: 12 }}>
        <Collapse
          activeKey={globalCollapseKeys}
          onChange={(key) => setGlobalCollapseKeys(key)}
          style={{ marginBottom: 12 }}
          items={[
            { 
              key: '1', 
              label: (<>
                <Tooltip placement="top" title="Tokens available to all users of MapsForTabletop, suited for a wide variety of purposes">
                  Global Tokens ({matchFilterGlobal})
                </Tooltip>
              </>), 
              children: (
                <>
                  <List
                    grid={{
                      gutter: 16,
                      xs: 3,
                      sm: 3,
                      md: 3,
                      lg: 3,
                      xl: 3,
                      xxl: 3,
                    }}
                    style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                    dataSource={displayGameTokens ? displayGameTokens.filter((t) => t.is_global) : []}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Card title={<>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>{filterTitle(item.display_name, item.highlightTitleIndexes)}</Typography>
                            <Tooltip mouseEnterDelay={0.3} title={"Edit"}>
                              <Button type="dashed" icon={<SettingOutlined />} onClick={() => {}} />
                            </Tooltip>
                          </div>
                        </>}>
                          <div style={{ maxWidth: '100%', maxHeight: '100%' }} className="constrainChildren">
                            <Image
                              style={{ display: 'block', width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%'}}
                              src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${session.user.id}/${item.background_image}`}
                              preview={false}
                            />
                          </div>
                        </Card>
                      </List.Item>
                    )} />
                </>
              ),
            }
          ]}
        />
        <Collapse
          activeKey={localCollapseKeys}
          onChange={(key) => setLocalCollapseKeys(key)}
          items={[
            { 
              key: '1', 
              label: (<>
                <Tooltip placement="top" title="Tokens you have personally uploaded to this game">
                  My Tokens ({matchFilterLocal})
                </Tooltip>
              </>), 
              children: (
                <>
                  <List
                    grid={{
                      gutter: 16,
                      xs: 3,
                      sm: 3,
                      md: 3,
                      lg: 3,
                      xl: 3,
                      xxl: 3,
                    }}
                    style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                    dataSource={displayGameTokens ? displayGameTokens.filter((t) => !t.is_global) : []}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Card title={<>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>{filterTitle(item.display_name, item.highlightTitleIndexes)}</Typography>
                            <Tooltip mouseEnterDelay={0.3} title={"Edit"}>
                              <Button type="dashed" icon={<SettingOutlined />} onClick={() => {}} />
                            </Tooltip>
                          </div>
                        </>}>
                          <div style={{ maxWidth: '100%', maxHeight: '100%' }} className="constrainChildren">
                            <Image
                              style={{ display: 'block', width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%'}}
                              src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${session.user.id}/${item.background_image}`}
                              preview={false}
                            />
                          </div>
                        </Card>
                      </List.Item>
                    )} />
                </>
              ),
            }
          ]}
        />
      </div>
      <CreateTokenModal isModalOpen={createTokenModalOpen} setIsModalOpen={setCreateTokenModalOpen} forceRefresh={() => setForceRefresh(true)} />
    </>
  );
}