import * as React from "react";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import { useMainMenuState } from "../MainMenu";
import { View, useGlobalState } from "../GlobalState";
import { shallow } from "zustand/shallow";
import { DatabaseGameData, DatabaseMap, MapAutoComplete } from "../../../types";
import {
  Breadcrumb,
  Button,
  Card,
  Input,
  List,
  Popover,
  Skeleton,
  Typography,
  message,
  Image,
  Tooltip,
  Space,
  AutoComplete,
  Popconfirm
} from "antd";
import { CloseOutlined, InfoOutlined, LoginOutlined, RollbackOutlined, SearchOutlined } from "@ant-design/icons";
import { useHover } from "usehooks-ts";
import * as fuzzysort from "fuzzysort";
import { filterTitle, formatBytes } from "../../../util/filterutil";
import Map from 'ol/Map';
import CreateMapModal from "../Operations/CreateMapModal";
import { useMapState } from "../../Map/MapDisplay";
import {FaRegTrashAlt} from "react-icons/fa";

interface Props {

}

export default function GameMaps({ }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);

  const editingGameId = useGlobalState((state) => state.editingGameId);
  const [editingMapId, setEditingMapId] = useGlobalState((state) => [state.editingMapId, state.setEditingMapId], shallow);

  const [editingGameData, setEditingGameData] = useMainMenuState((state) => [state.editingGameData, state.setEditingGameData], shallow);

  const [originalGameMaps, setOriginalGameMaps] = useMainMenuState((state) => [state.originalGameMaps, state.setOriginalGameMaps], shallow);
  const [displayGameMaps, setDisplayGameMaps] = useMainMenuState((state) => [state.displayGameMaps, state.setDisplayGameMaps], shallow);

  const [forceRefresh, setForceRefresh] = useMainMenuState((state) => [state.forceRefresh, state.setForceRefresh], shallow);
  const setSelectedTab = useMainMenuState((state) => state.setSelectedTab);

  const [createMapModalOpen, setCreateMapModalOpen] = useState(false);

  const [filterMaps, setFilterMaps] = useState<string>();

  const addNewRef = useRef(null);
  const addNewHovered = useHover(addNewRef);

  const [msg, contextHolder] = message.useMessage();

  const setView = useGlobalState((state) => state.setView);
  const setEditingMapData = useGlobalState((state) => state.setEditingMapData);
  
  const [mapAutoCompletes, setMapAutoCompletes] = useState<MapAutoComplete[] | undefined>();
  const [workingDefaultMap, setWorkingDefaultMap] = useState<string>(undefined);
  const [mapLinkOptions, setMapLinkOptions] = useState<MapAutoComplete[]>([]);
  
  useEffect(() => {
    setForceRefresh(true);
  }, [editingGameData]);

  useEffect(() => {
    if (editingGameId && supabase && session) {
      // Send a request to try to get it
      const getData = async () => {
        supabase.from('games')
          .select('*')
          .eq('owner_id', session.user.id)
          .eq('game_id', editingGameId)
          .limit(1)
          .single()
          .then(({data, error}) => {
            console.log('got data', data);
            if (data) {
              setEditingGameData(data as DatabaseGameData);
            } else {
              msg.error('Could not find that game!');
            }
          });
      };

      getData();
    }
  }, [editingGameId, setEditingGameData, supabase, session]);

  useEffect(() => {
    if (originalGameMaps && !forceRefresh)
      return;

    if (supabase && session && session.user && editingGameId && editingGameData) {
      setWorkingDefaultMap(undefined);

      const getData = async () => {
        const { error, data } = await supabase
          .from('maps')
          .select('*')
          .eq('owner_id', session.user.id)
          .eq('game_id', editingGameId);

        if (data) {
          const newMaps = data.map((data) => {
            if (data.initiative)
              data.initiative = JSON.parse(data.initiative);
            if (data.timesettings)
              data.timesettings = JSON.parse(data.timesettings);
            if (data.drawings)
              data.drawings = JSON.parse(data.drawings);
            return data as DatabaseMap;
          });
          setOriginalGameMaps(newMaps);

          if (editingGameData.default_map_id) {
            setWorkingDefaultMap(newMaps.find((item) => item.map_id == editingGameData.default_map_id)?.display_name || undefined);
          }

          const items = data.map((item) => ({
            value: item.display_name,
            label: item.display_name,
          }));
          setMapAutoCompletes(items);
          setMapLinkOptions(items);

          setForceRefresh(false);
        }
      };

      getData();
    }
  }, [editingGameId, supabase, session, setOriginalGameMaps, forceRefresh, setForceRefresh, originalGameMaps, editingGameData]);

  useEffect(() => {
    if (!originalGameMaps)
      return;

    let result: DatabaseMap[] = [];
    if (filterMaps && filterMaps.length > 0) {
      fuzzysort.cleanup();
      result = fuzzysort.go(filterMaps, originalGameMaps, { key: 'display_name' }).map((i) => {
        return {
          ...i.obj,
          highlightTitleIndexes: (i as any)._indexes
        }
      });
    } else
      result = originalGameMaps;
    setDisplayGameMaps(result);
  }, [originalGameMaps, setDisplayGameMaps, filterMaps, forceRefresh]);

  // Let us conveniently filter any default map.
  useEffect(() => {
    if (!mapAutoCompletes)
      return;

    let result: MapAutoComplete[] = [];
    if (workingDefaultMap) {
      fuzzysort.cleanup();
      result = fuzzysort.go(workingDefaultMap, mapAutoCompletes, {key:'value'}).map((i) => {
        return {
          ...i.obj,
          label: filterTitle((i as any).obj.value, (i as any)._indexes)
        }
      });
    } else
      result = mapAutoCompletes;
    setMapLinkOptions(result);
  }, [mapAutoCompletes, workingDefaultMap]);

  // Updating the default map
  useEffect(() => {
    if (!editingGameData || !editingGameId)
      return;

    if (!originalGameMaps || originalGameMaps.length == 0)
      return;

    // Don't need to update the current value.
    if (workingDefaultMap) {
      const matchingOne = originalGameMaps.find((item) => item.display_name == workingDefaultMap)?.map_id || undefined;
      if (matchingOne == editingGameData.default_map_id)
        return;
      if (matchingOne == undefined && editingGameData.default_map_id !== undefined)
        return;
    }
    if (workingDefaultMap == editingGameData.default_map_id)
      return;

    const updateDefaultMap = setTimeout(() => {
      const matchingMap = originalGameMaps.find((item) => item.display_name == workingDefaultMap);
      if (!matchingMap)
        return;

      supabase
        .from('games')
        .update({
          default_map_id: matchingMap.map_id
        })
        .eq('game_id', editingGameId)
        .eq('owner_id', session.user.id)
        .then((res) => {
          setEditingGameData({
            ...editingGameData,
            default_map_id: matchingMap.map_id
          });
        })
    }, 300);

    return () => clearTimeout(updateDefaultMap);
  }, [workingDefaultMap, editingGameId, editingGameData, setEditingGameData, originalGameMaps]);

  const deleteMap = useCallback((mapId: string) => {
    supabase
      .from('maps')
      .delete()
      .eq('map_id', mapId).then(({error, data}) => {
      if (!error) {
        setForceRefresh(true);
      }
    });
  }, [supabase]);

  if (!editingGameData || !originalGameMaps) {
    return <Skeleton />;
  }

  interface ProperDimensions {
    width: number;
    height: number;
  }

  const resizeWithAspectRatio = (maxSize: number, data: DatabaseMap): ProperDimensions => {
    const { projection_x: width, projection_y: height } = data;
    if (width > height) {
      const factor = maxSize / width;

      return {
        width: maxSize,
        height: height * factor
      };
    } else {
      const factor = maxSize / height;

      return {
        width: width * factor,
        height: maxSize,
      }
    }
  }

  return (
    <>
      {contextHolder}
      <Typography.Title level={1} style={{ margin: 0 }}>
        {editingGameData.display_name}
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <a onClick={() => setSelectedTab(['details'])}>Game</a>,
          },
          {
            title: 'Maps',
          },
        ]}
      />
      <div style={{ marginTop: 12, marginBottom: 8 }}>
        {/* TODO make this a step based selector? sterp 1 says go to site and pick a font? etc...*/}
        <Typography.Title level={4} style={{ marginTop: 0 }}>Select a Default Map</Typography.Title>
        <AutoComplete 
          value={workingDefaultMap}
          options={mapLinkOptions}
          style={{ width: '100%' }}
          onSelect={(val) => {
            setWorkingDefaultMap(val);
          }}
          onSearch={(val) => {
            setWorkingDefaultMap(val);
          }}
          onChange={(val) => {
            setWorkingDefaultMap(val);
          }}
          placeholder="Default Map"
        />
      </div>
      <Typography.Title level={4} style={{ marginTop: 10, marginBottom: 12 }}>Browse Individual Maps</Typography.Title>
      <div style={{ display: 'flex', marginBottom: 12 }}>
        <Input size="large" placeholder="Filter your maps..." prefix={<SearchOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between' }} value={filterMaps} onChange={(change) => setFilterMaps(change.target.value)} />
        <Button type={addNewHovered ? "primary" : 'default'} size='large' style={{ marginLeft: 12 }} ref={addNewRef} onClick={() => setCreateMapModalOpen(true)}>
          Create New?
        </Button>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 3,
          sm: 3,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
        dataSource={displayGameMaps}
        renderItem={(item, index) => (
          <List.Item>
            <Card title={<>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{filterTitle(item.display_name, item.highlightTitleIndexes)}</Typography>
                <div>
                  <Tooltip mouseEnterDelay={0.3} title={"Open this map for editing"}>
                    <Button type="dashed" icon={<LoginOutlined />} onClick={() => {
                      setEditingMapId(item.map_id);
                      setEditingMapData(item);
                      setView(View.Map);
                    }}>
                      Open
                    </Button>
                  </Tooltip>
                  <Popconfirm
                    title="Delete this map?"
                    description={`Are you sure you want to delete the map ${item.display_name}?`}
                    okText={"Yes"}
                    cancelText={"No"}
                    onConfirm={() => {
                      deleteMap(item.map_id);
                    }}
                  >
                    <Tooltip mouseEnterDelay={0.3} title={"Delete this map"}>
                      <Button type="dashed" danger icon={<FaRegTrashAlt />} onClick={() => setEditingMapId(item.map_id)} />
                    </Tooltip>
                  </Popconfirm>
                </div>
              </div>
            </>}>
              <div style={{ maxWidth: '100%', maxHeight: '100%' }} className="constrainChildren">
                <Image
                  width={resizeWithAspectRatio(200, item).width}
                  height={resizeWithAspectRatio(200, item).height}
                  src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${session.user.id}/${item.background_image}`}
                  preview={false}
                />
              </div>
            </Card>
          </List.Item>
        )} />

        <CreateMapModal isModalOpen={createMapModalOpen} setIsModalOpen={setCreateMapModalOpen} setForceRefresh={setForceRefresh} />
    </>
  );
}