import {useGlobalState} from "../../../Menu/GlobalState";
import {shallow} from "zustand/shallow";
import {useMapState} from "../../MapDisplay";
import React, {useEffect, useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
import {Button, Slider, Typography} from "antd";
import dayjs from "dayjs";
import {FaAngleRight, FaAnglesRight, FaRegClock} from "react-icons/fa6";
import {DatabaseMap, DatabaseMapPoi, TimeSettings} from "../../../../types";
import {v4 as uuidv4} from "uuid";
import {useMainMenuState} from "../../../Menu/MainMenu";

const fade = keyframes`
    0% {
        opacity: 0.2;
    }

    1% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
`;
const fadeOut = keyframes`
    0% {
        opacity: 1;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
`;

const TimeDrawer = styled.div`
    z-index: 90;
    width: 300px;
    position: absolute;
    top: 8px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;
    background: rgb(23, 23, 23);
    border-radius: 8px;
    opacity: 0.2;
    animation-name: ${fadeOut};
    animation-duration: 0.4s;

    & h2 {
        margin: 0 !important;

        & svg {
            width: 0.75em;
            height: 0.75em;
            margin-right: 8px;
        }
    }

    &:hover {
        opacity: 1;
        animation-name: ${fade};
        animation-duration: 0.4s;
    }
`;

const TimeAndTitle = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    width: 169px;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`;

interface Props {

}

const getTodayZeroHours = () => {
  const toReturn = new Date();
  toReturn.setHours(0, 0, 0, 0);
  return toReturn;
};

const addHoursToDate = (d: Date, hours: number) => {
  d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
  return d;
}

export default function TimeSlider({}: Props) {
  const originalGameTokens = useMainMenuState((state) => state.originalGameTokens);
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const [poiCoordinateChanges, setPoiCoordinateChanges] = useGlobalState((state) => [state.poiCoordinateChanges, state.setPoiCoordinateChanges], shallow);
  const amGM = useMapState((state) => state.amGM);
  const supabase = useGlobalState((state) => state.supabase);
  const anyModalOpen = useGlobalState((state) => state.anyModalOpen);
  const [time, setTime] = useState(-1);

  const startDate = addHoursToDate(getTodayZeroHours(), 19);
  const endDate = addHoursToDate(getTodayZeroHours(), 25);
  console.log(startDate, endDate)
  const stepMinutes = 5;

  const differenceMinutes = Math.abs(dayjs(startDate).diff(endDate, 'minutes'));
  const totalSteps = differenceMinutes / stepMinutes;
  const stepUnit = 1 / totalSteps;

  const [updates, setUpdates] = useState(0);
  const toMovePoiIdsTo = useRef<{ [key: string]: number[] }>({});
  const nextLerpInstant = useRef<boolean>(false);

  // Pull the current time from the map at the start
  useEffect(() => {
    if (time > -1)
      return;
    if (!editingMapData)
      return;

    if (!editingMapData.timesettings)
      return;

    const currentTime = parseFloat(window.localStorage.getItem('currentTime') ?? '0');

    setTime(currentTime * stepUnit);
    nextLerpInstant.current = true;
  }, [time, editingMapData]);

  // Update the time on the map and work out where we want to move pois to
  useEffect(() => {
    const updateInterval = () => {
      const clone = structuredClone(editingMapData) as DatabaseMap;
      const settings = (clone.timesettings ?? {}) as TimeSettings;
      window.localStorage.setItem('currentTime', (time / stepUnit).toString());

      const instant = nextLerpInstant.current;

      const offSchedule = JSON.parse(window.localStorage.getItem('offSchedule') ?? '[]') as string[];

      /**
       * Now that we've adjusted the time:
       * - Go through all POIs on the map
       *  -> If it is in 'offSchedule', ignore it
       *  -> If it has no schedule, ignore it.
       * - If the poi has a schedule and is not off schedule, we need to
       *   interpolate its position between the two nearest schedule points.
       */

      if (settings.schedule) {
        const stepNow = parseFloat(window.localStorage.getItem('currentTime') ?? '0');
        const keys = Object.keys(settings.schedule);
        for (let i = keys.length - 1; i >= 0; i--) {
          const thisMapText = keys[i];
          if (offSchedule.includes(thisMapText))
            continue;

          const schedule = settings.schedule[thisMapText];
          //#region First we need to determine if this POI should exist or not (and what position it should exist at, if so)

          let shouldExist = true;
          let desiredX: number = 0;
          let desiredY: number = 0;

          const earliest = schedule[0];
          const latest = schedule[schedule.length - 1];

          if (stepNow < earliest.step || schedule.length == 1) {
            if (earliest.isCreation && stepNow < earliest.step)
              shouldExist = false;

            desiredX = earliest.position[0];
            desiredY = earliest.position[1];
          } else if (stepNow > latest.step) {
            if (latest.isDestroy)
              shouldExist = false;

            desiredX = latest.position[0];
            desiredY = latest.position[1];
          } else {
            let leftIndex = 0;
            for (let sIndex = 0; sIndex < schedule.length; sIndex += 1) {
              const thatStep = schedule[sIndex];
              // We've reached one in the future
              if (thatStep.step > stepNow)
                break;

              if (thatStep.step < stepNow)
                leftIndex = sIndex;

              if (thatStep.isDestroy)
                shouldExist = false;
              if (thatStep.isCreation)
                shouldExist = true;
            }

            const prior = schedule[leftIndex];
            const after = schedule[leftIndex + 1];

            const stepDifference = after.step - prior.step;
            const lerpT = (stepNow - prior.step) / stepDifference;

            const positionDifferenceX = after.position[0] - prior.position[0];
            const positionDifferenceY = after.position[1] - prior.position[1];

            desiredX = prior.position[0] + (positionDifferenceX * lerpT);
            desiredY = prior.position[1] + (positionDifferenceY * lerpT);
          }

          //#endregion

          //#region Make it exist or not
          const thisPoiIndex = clone.pois.findIndex((p) => p.map_text === thisMapText);
          const thisPoi = clone.pois[thisPoiIndex];
          console.log(shouldExist, thisPoi !== undefined);
          if (shouldExist !== (thisPoi !== undefined)) {
            // Checks if should exist equals "does exist"
            // If not, we need to make the correct state happen.
            if (shouldExist) {
              const newPoi = {
                poi_id: uuidv4(),
                map_text: thisMapText,
                has_description: false,
                map_id: clone.map_id,
                coordinate_x: desiredX,
                coordinate_y: desiredY,
                owner_id: clone.owner_id,
                circle_clickbox: false,
                is_image: true,
                extra_json_data: {},
                find_zoom_amount: 4,
              } as DatabaseMapPoi;
              clone.pois.push(newPoi);
            } else {
              clone.pois.splice(clone.pois.findIndex((poi) => poi.map_text === thisMapText), 1);
            }

            continue;
          }
          //#endregion

          // Don't process position changes for things that should not exist.
          if (!shouldExist)
            continue;

          if (!instant) {
            toMovePoiIdsTo.current[thisPoi.poi_id] = [
              desiredX,
              desiredY,
              thisPoi.coordinate_x,
              thisPoi.coordinate_y
            ];
          }

          thisPoi.coordinate_x = desiredX;
          thisPoi.coordinate_y = desiredY;
          clone.pois[thisPoiIndex] = thisPoi;
        }
      }
      clone.timesettings = settings;

      if (instant) {
        setEditingMapData(clone);
        nextLerpInstant.current = false;
      } else {
        setTimeout(() => {
          setEditingMapData(clone);
        }, 350);
      }
      setUpdates((last) => last + 1);
    };

    const timer = setTimeout(updateInterval, 700);
    return () => {
      clearTimeout(timer);
    }
  }, [time]);

  // Move our pois using interpolation
  useEffect(() => {
    const currentPoiCoordinates = poiCoordinateChanges || {};
    const toMove = toMovePoiIdsTo.current;

    for (let poiId in toMove) {
      currentPoiCoordinates[poiId] = toMove[poiId];
    }

    if (Object.keys(currentPoiCoordinates).length > 0)
      setPoiCoordinateChanges(Object.assign({}, currentPoiCoordinates));
  }, [updates, setPoiCoordinateChanges]);

  if (!supabase || !editingMapData || !editingMapData.timesettings)
    return (<></>);

  if (time < 0)
    return (<></>);

  const onSteps = time / stepUnit;
  const minutes = onSteps * stepMinutes;
  const selectedDate = dayjs(startDate).add(minutes, 'minutes');

  return (
    <TimeDrawer>
      <TimeAndTitle>
        <Typography.Title level={2}>
          <FaRegClock/>
          {selectedDate.format('h:mm a')}
        </Typography.Title>
        <Slider
          min={0}
          max={1}
          defaultValue={0}
          tooltip={{
            formatter: (value: number) => {
              const numberSteps = value / stepUnit;
              const minutes = numberSteps * stepMinutes;
              const newDate = dayjs(startDate).add(minutes, 'minutes');
              return <>{newDate.format('h:mm a')}</>
            }
          }}
          value={time}
          onChange={(value: number) => setTime(value)}
          step={stepUnit}
        />
      </TimeAndTitle>
      <Buttons>
        <Button disabled={onSteps == totalSteps} style={{height: '100%'}} onClick={() => {
          if (onSteps == totalSteps)
            return;
          setTime(time + stepUnit)
        }}>
          <FaAngleRight/>
        </Button>
        <Button disabled={onSteps == totalSteps} style={{height: '100%'}} onClick={() => {
          if (onSteps == totalSteps)
            return;
          const newSteps = Math.min(onSteps + 6, totalSteps);
          const change = newSteps - onSteps;
          setTime(time + (change * stepUnit));
        }}>
          <FaAnglesRight/>
        </Button>
      </Buttons>
    </TimeDrawer>
  )
}