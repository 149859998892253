import React, { ReactNode, useEffect, useReducer, useRef, useState } from "react";
import { useGlobalState } from "../GlobalState";
import AnnouncementsCreation from "./AnnouncementsCreation";
import { Breadcrumb, Button, Popconfirm, Skeleton, Tag, Timeline, TimelineItemProps, TimelineProps, Typography } from "antd";
import RichEditor from "../../Remirror/RichEditor";
import { useMapState } from "../../Map/MapDisplay";
import { useMainMenuState } from "../MainMenu";

export default function AnnouncementsPage() {
  const userData = useGlobalState((state) => state.userData);
  const supabase = useGlobalState((state) => state.supabase);

  const [timelineItems, setTimelineItems] = useState<TimelineItemProps[]>([]);
  const newestStoredAnnouncementId = useGlobalState((state) => state.lastStoredAnnouncementId);

  useEffect(() => {
    if (!supabase)
      return;

    supabase
      .from('announcements')
      .select('*')
      .limit(50)
      .order('created_at', {ascending: false})
      .then((response) => {
        if (response.data) {
          const newData = response.data;

          const currentTagColors = ['magenta', 'green', 'geekblue', 'orange', 'gold', 'blue', 'red', 'volcano', 'lime', 'purple', 'cyan']
          const tagColors: {[key: string]: string} = {};

          setTimelineItems(response.data.map((entry) => {

            let data: ReactNode[] = [];
            if (entry.tags && entry.tags.length > 0) {
              entry.tags.split(',')?.forEach((tag: string) => {
                
                let tagColor: string = undefined;
                if (tag in tagColors)
                  tagColor = tagColors[tag];
                else {
                  const first = currentTagColors.shift();
                  tagColors[tag] = first;
                  tagColor = first;
                }

                data.push((<Tag color={tagColor} style={{ marginLeft: '8px', marginRight: '8px' }}>{tag}</Tag>));
              })
            }

            return {
              children: (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', color: 'rgb(113, 113, 113)' }}>
                    {new Date(entry.created_at).toLocaleString('en-US')}
                    {...(data)}
                  </div>
                  <RichEditor 
                    stateJson={entry.text}
                    editable={false}
                    reset={false}
                    setReset={() => {}}
                    changeStateJson={() => {}}
                  />
                  {userData?.is_admin ? (
                    <Popconfirm
                      title="Delete this announcement?"
                      description="Are you sure you want to delete this announcement?"
                      onConfirm={(e) => {
                        supabase
                          .from('announcements')
                          .delete()
                          .eq('id', entry.id)
                          .then((response) => {
                            window.location.reload();
                          });
                      }}
                      onCancel={(e) => {
                      }}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button type="dashed">
                        Delete?
                      </Button>
                    </Popconfirm>
                  ) : (<></>)}
                </>
              )
            }
          }));
        }
      })
  }, [supabase, userData, newestStoredAnnouncementId]);

  if (!supabase)
    return <Skeleton active />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll'}}>
      <Typography.Title level={1} style={{ margin: 0, marginBottom: '12px' }}>
        Announcements
      </Typography.Title>
      <AnnouncementsCreation />
      <div style={{ flexGrow: 1, overflowY: 'scroll', padding: '12px' }}>
        <Timeline 
          items={timelineItems}
        />
      </div>
    </div>
  );
}