import { SupabaseClient } from "@supabase/supabase-js";
import { Form, Input, Modal, Switch, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useGlobalState } from "../GlobalState";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void;
  forceRefresh: () => void;
}

export default function CreateGameModal({isModalOpen, setIsModalOpen, forceRefresh}: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    form.validateFields();
  }, [form]);

  const handleOk = () => {
    const { displayname, description, isprivate } = form.getFieldsValue();

    if (!displayname || !description) {
      messageApi.open({
        type: 'error',
        content: 'Please provide a title and description'
      });
    }

    setSubmitLoading(true);
    const formData = new FormData();
    formData.append('title', displayname);
    formData.append('description', description);
    formData.append('isPrivate', isprivate ?? false);
    supabase.functions.invoke('create-game', {
      body: formData
    }).then((response) => {
      if (response.error) {
        messageApi.open({
          type: 'error',
          content: response.error
        })
      } else {
        forceRefresh();
      }
      setIsModalOpen(false);
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 28 },
  };

  return (
    <Modal centered title="Create New Game" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okButtonProps={{ loading: submitLoading }} cancelButtonProps={{ disabled: submitLoading }}>
      <Form form={form} name="dynamic_rule"autoComplete="off">
        <Form.Item
          {...formItemLayout}
          name="displayname"
          label="Display Name"
          rules={[{ required: true, message: 'Please put in a name' }]}
        >
          <Input placeholder="Name for this game" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please put in a description' }]}
        >
          <TextArea rows={4} placeholder={'Description your game in brief'} />
        </Form.Item>
        <Form.Item 
          {...formItemLayout}
          name="isprivate"
          label="Is Private?" 
          valuePropName="checked">
            <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
};