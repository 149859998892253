import * as React from "react";
import Map from 'ol/Map'
import InnerMapWrapper from "./Internal/InnerMapWrapper";
import { ShortcutState, View, useGlobalState } from "../Menu/GlobalState";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import { RealtimeChannel, Session, SupabaseClient } from "@supabase/supabase-js";
import {shallow} from 'zustand/shallow';
import {
  Button,
  ColorPicker,
  FloatButton,
  Input,
  InputRef,
  Menu,
  notification,
  Popover,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { create } from "zustand";
import {
  DatabaseGamePlayer,
  DatabaseMap,
  DatabaseMapPoi,
  DatabaseToken,
  MapAutoComplete as MapAutoComplete,
  MapGuidMapping, MapHistoryEntry,
  MousePosition,
  PlayerTableState, RequestedMovement, SvgPathDrawSettings,
  ToolType,
  TrayTool
} from '../../types';
import diff from "microdiff";
import AddFeature from "./Controls/AddFeature";
import {v4 as uuidv4} from 'uuid';
import ToggleDebugFeature from "./Controls/ToggleDebugFeature";
import ReturnToMenu from "./Controls/ReturnToMenu";
import ToggleViewAsPlayer from "./Controls/ToggleViewAsPlayer";
import TokenSidebar from "./Controls/TokenSidebar/TokenSidebar";
import { Coordinate } from "ol/coordinate";
import MapSettings from "./Controls/MapSettings";
import SupabaseRoom from "../Supabase/SupabaseRoom";
import CreatePlayerModal from "../Supabase/Modals/CreatePlayerModal";
import PlayerSidebar from "./Controls/PlayerSidebar/PlayerSidebar";
import ToolTray from "./Controls/Tray/ToolTray";
import MapUI from "./MapUI";
import Tool from "./Controls/Tray/Tool";
import { DragOutlined, EnterOutlined } from "@ant-design/icons";
import { PiPolygonDuotone } from "react-icons/pi";
import {MdDraw, MdHistory, MdOutlineGeneratingTokens} from "react-icons/md";
import { BsCircle, BsCommand, BsEraser, BsPaintBucket } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { CommandMenu, CommandWrapper, useKmenu, useCommands } from 'kmenu';
import DynamicKMenu from "./Commands/DynamicKMenu";
import { Feature } from "ol";
import VectorSource from "ol/source/Vector";
import { LuRuler } from "react-icons/lu";
import OTPLogin from "./EasyLogin/OTPLogin";
import InitiativeTracker from "./Controls/InitiativeTracker/InitiativeTracker";
import DiceRolling, {DiceRollOpenKey} from "./Dice/DiceRolling";
import FeatureDescription from "./Controls/FeaturePopover/FeatureDescription";
import {PresetsItem} from "antd/es/color-picker/interface";
import {useLocalStorage} from "usehooks-ts";
import {RiBarricadeFill} from "react-icons/ri";
import {FaDiceD20, FaTrashCan} from "react-icons/fa6";
import D4 from "../Utility/Dice/D4";
import D6 from "../Utility/Dice/D6";
import D8 from "../Utility/Dice/D8";
import D20 from "../Utility/Dice/D20";
import D10 from "../Utility/Dice/D10";
import D12 from "../Utility/Dice/D12";
import {IoMdCheckmark} from "react-icons/io";
import HistoryDrawer, {HistoryDrawerModalKey, MapSettingsModalKey} from "./Controls/HistoryDrawer/HistoryDrawer";
import {FaHistory} from "react-icons/fa";
import {DefaultFont} from "../../util/config";
import ChangeTextPoiColorModal, {TextPoiColorModalOptions} from "../Utility/ChangeTextPoiColorModal";
import NewTutorial from "./Tutorial/NewTutorial";
import {LiaChalkboardTeacherSolid} from "react-icons/lia";
import TimeSlider from "./Controls/TimeSlider/TimeSlider";

interface Props {
}

interface WaitingToAdd {
  backgroundImage: string;
  time: number;
  tokenData?: DatabaseToken | undefined;
  playerData?: DatabaseGamePlayer | undefined,
  addingExtraSvgInfo?: string;
}

export const NormalDrawingColors = [
  '#FFFFFF',
  '#000000',
  '#F5222D',
  '#FA8C16',
  '#FADB14',
  '#8BBB11',
  '#52C41A',
  '#13A8A8',
  '#1677FF',
  '#2F54EB',
  '#722ED1',
  '#EB2F96',
];

export interface MapState {
  map?: Map | undefined,
  setMap: (m: Map | undefined) => void,

  mouseOverFeature?: DatabaseMapPoi | undefined,
  setMouseOverFeature: (poi: DatabaseMapPoi | undefined) => void,
  
  mouseOverMovementApproval?: RequestedMovement | undefined,
  setMouseOverMovementApproval: (movement: RequestedMovement| undefined) => void,

  hoverDrawFeature?: Feature | undefined,
  setHoverDrawFeature: (feat: Feature | undefined) => void,

  myDrawSource?: VectorSource | undefined,
  setMyDrawSource: (b: VectorSource | undefined) => void,
  
  fillMode?: 'fill' | 'unwalkable',
  setFillMode: (f: 'fill' | 'unwalkable') => void,

  localDrawSource?: VectorSource | undefined,
  setLocalDrawSource: (b: VectorSource | undefined) => void,

  mapDebug: boolean,
  setMapDebug: (b: boolean) => void,

  mapAutoCompletes?: MapAutoComplete[] | undefined,
  setMapAutoCompletes: (m: MapAutoComplete[] | undefined) => void,

  mapGuidMappings?: MapGuidMapping[] | undefined,
  setMapGuidMappings: (m: MapGuidMapping[] | undefined) => void,

  mapReadyForDisplay: boolean,
  setMapReadyForDisplay: (b: boolean) => void,

  viewAsPlayer: boolean,
  setViewAsPlayer: (b: boolean) => void,

  tokenSidebarOpen: boolean,
  setTokenSidebarOpen: (b: boolean) => void,

  amGM?: boolean,
  setAmGM: (b: boolean) => void,

  playerData?: DatabaseGamePlayer | undefined,
  setPlayerData: (d: DatabaseGamePlayer | undefined) => void,

  needsPlayerData?: boolean,
  setNeedsPlayerData: (b: boolean) => void,

  tablePlayersList: DatabaseGamePlayer[],
  setTablePlayersList: (d: DatabaseGamePlayer[] | undefined) => void,

  onlinePlayerIds: string[],
  setOnlinePlayerIds: (b: string[] | undefined) => void,

  ownerId?: string,
  setOwnerId: (b: string) => void,

  trayFocusedTool?: ToolType | undefined,
  setTrayFocusedTool: (b: ToolType | undefined) => void,

  trayTwoFocusedTool?: ToolType | undefined,
  setTrayTwoFocusedTool: (b: ToolType | undefined) => void,

  playerTableState: PlayerTableState,
  setPlayerTableState: (b: PlayerTableState) => void,

  allPlayerTableStates: {[key: string]: PlayerTableState},
  setAllPlayerTableStates: (b: {[key: string]: PlayerTableState}) => void,

  currentZoomFromOtherMap?: { toCoordinate: Coordinate, map_id: string, zoom: number } | undefined,
  setCurrentZoomFromOtherMap: (b: { toCoordinate: Coordinate, map_id: string } | undefined) => void,

  forceMapDrawingSync: boolean,
  setForceMapDrawingSync: (b: boolean) => void,
  
  channels: {[key: 'main' | string]: RealtimeChannel},
  setChannels: (c: ((prev: ({[key: 'main' | string]: RealtimeChannel})) => ({[key: 'main' | string]: RealtimeChannel})) | ({[key: 'main' | string]: RealtimeChannel})) => void,

  forceMapMovePoi?: DatabaseMapPoi | undefined,
  setForceMapMovePoi: (b: DatabaseMapPoi | undefined) => void,
  
  myDrawColor?: string | undefined,
  setMyDrawColor: (s: string | undefined) => void,
  
  poiColorModalOptions?: TextPoiColorModalOptions,
  setPoiColorModalOptions: (opt?: TextPoiColorModalOptions | undefined) => void,
  
  recentDrawColors: string[],
  setRecentDrawColors: (fn: string[] | ((prev: string[]) => string[])) => void,
  
  workingMoveArrows: {[poi_id: string]: {path: Coordinate[][], stamp: number, state: 'wip' | 'pending_acceptance' | 'moving'}},
  setWorkingMoveArrows: (fn: ((previous: {[poi_id: string]: {path: Coordinate[][], stamp: number, state: 'wip' | 'pending_acceptance' | 'moving'}}) => {[poi_id: string]: {path: Coordinate[][], stamp: number, state: 'wip' | 'pending_acceptance' | 'moving'}})) => void,
  
  tutorialDrawerOpen: boolean,
  setTutorialDrawerOpen: (b: boolean) => void,
}

export const useMapState = create<MapState>((set) => ({
  setMap: (map: Map | undefined) => set((state) => ({
    map: map
  })),

  setMouseOverFeature: (poi: DatabaseMapPoi | undefined) => set((state) => ({
    mouseOverFeature: poi,
  })),
  
  setMouseOverMovementApproval: (approval: RequestedMovement | undefined) => set((state) => ({
    mouseOverMovementApproval: approval,
  })),
  
  mapDebug: false,
  setMapDebug: (b: boolean) => set((state) => ({
    mapDebug: b
  })),

  setMapAutoCompletes: (m: MapAutoComplete[] | undefined) => set((state) => ({
    mapAutoCompletes: m,
  })),

  setMapGuidMappings: (m: MapGuidMapping[] | undefined) => set((state) => ({
    mapGuidMappings: m,
  })),

  mapReadyForDisplay: false,
  setMapReadyForDisplay: (b: boolean) => set((state) => ({
    mapReadyForDisplay: b,
  })),

  viewAsPlayer: false,
  setViewAsPlayer: (b: boolean) => set((state) => ({
    viewAsPlayer: b,
  })),

  tokenSidebarOpen: false,
  setTokenSidebarOpen: (b: boolean) => set((state) => ({
    tokenSidebarOpen: b
  })),

  setAmGM: (b: boolean) => set((state) => ({
    amGM: b,
  })),

  setPlayerData: (d: DatabaseGamePlayer | undefined) => set((state) => ({
    playerData: d,
  })),

  needsPlayerData: false,
  setNeedsPlayerData: (b: boolean) => set((state) => ({
    needsPlayerData: b,
  })),

  tablePlayersList: [],
  setTablePlayersList: (d: DatabaseGamePlayer[] | undefined) => set((state) => ({
    tablePlayersList: d || [],
  })),

  setOwnerId: (b: string) => set((state) => ({
    ownerId: b,
  })),

  onlinePlayerIds: [],
  setOnlinePlayerIds: (d: string[] | undefined) => set((state) => ({
    onlinePlayerIds: d || [],
  })),

  setTrayFocusedTool: (b: ToolType | undefined) => set((state) => ({
    trayFocusedTool: b,
    trayTwoFocusedTool: ToolType.Unselectable,
  })),

  setTrayTwoFocusedTool: (b: ToolType | undefined) => set((state) => ({
    trayTwoFocusedTool: b,
  })),

  playerTableState: {},
  setPlayerTableState: (b: PlayerTableState) => set((state) => ({
    playerTableState: b,
  })),

  allPlayerTableStates: {},
  setAllPlayerTableStates: (b:  {[key: string]: PlayerTableState}) => set((state) => ({
    allPlayerTableStates: b,
  })),

  setCurrentZoomFromOtherMap: (b: { toCoordinate: Coordinate, map_id: string, zoom: number } | undefined) => set((state) => ({
    currentZoomFromOtherMap: b,
  })),

  setHoverDrawFeature: (feat: Feature | undefined) => set((state) => ({
    hoverDrawFeature: feat,
  })),

  setMyDrawSource: (b: VectorSource | undefined) => set((state) => ({
    myDrawSource: b,
  })),
  
  setLocalDrawSource: (b: VectorSource | undefined) => set((state) => ({
    localDrawSource: b,
  })),

  forceMapDrawingSync: false,
  setForceMapDrawingSync: (b: boolean) => set((state) => ({
    forceMapDrawingSync: b,
  })),

  setForceMapMovePoi: (b: DatabaseMapPoi | undefined) => set((state) => ({
    forceMapMovePoi: b,
  })),
  
  myDrawColor: window.localStorage.getItem('my-draw-color') ?? '#000000',
  setMyDrawColor: (s: string | undefined) => set((state) => {
    window.localStorage.setItem('my-draw-color', s);
    return ({
      myDrawColor: s,
    });
  }),
  
  recentDrawColors: window.localStorage.getItem('recent-draw-colors')?.split(',') ?? [],
  setRecentDrawColors: (fn: string[] | ((prev: string[]) => string[])) => {
    if (typeof(fn) != 'function') {
      window.localStorage.setItem('recent-draw-colors', fn.join(','));
      return set((state) => ({
        recentDrawColors: fn,
      }));
    }

    return set((state) => {
      const output = fn(state.recentDrawColors);
      window.localStorage.setItem('recent-draw-colors', output.join(','));
      
      return ({
        recentDrawColors: output
      });
    });
  },
  
  setPoiColorModalOptions: (opt?: TextPoiColorModalOptions | undefined) => set((state) => ({
    poiColorModalOptions: opt
  })),
  
  fillMode: 'fill',
  setFillMode: (f: 'fill' | 'unwalkable') => set((state) => ({
    fillMode: f,
  })),
  
  channels: {},
  setChannels: (c: ((prev: ({[key: 'main' | string]: RealtimeChannel})) => ({[key: 'main' | string]: RealtimeChannel})) | ({[key: 'main' | string]: RealtimeChannel})) => set((state) => {
    if (typeof(c) == 'function') {
      return ({
        channels: c(state.channels)
      });
    }
    
    return ({
      channels: c,
    });
  }),
  
  workingMoveArrows: {},
  setWorkingMoveArrows: (fn: ((previous: {[poi_id: string]: {path: Coordinate[][], stamp: number, state: 'wip' | 'pending_acceptance' | 'moving'}}) => {[poi_id: string]: {path: Coordinate[][], stamp: number, state: 'wip' | 'pending_acceptance' | 'moving'}})) => set((state) => ({
    workingMoveArrows: fn(state.workingMoveArrows)
  })),
  
  tutorialDrawerOpen: false,
  setTutorialDrawerOpen: (b: boolean) => set((state) => ({
    tutorialDrawerOpen: b,
  })),
}));

export default function MapDisplay({}: Props) {
  const editingGameId = useGlobalState((state) => state.editingGameId);
  const editingGameData = useGlobalState((state) => state.editingGameData);
  const [editingMapId, setEditingMapId] = useGlobalState((state) => [state.editingMapId, state.setEditingMapId], shallow);

  const session = useGlobalState((state) => state.session);
  const supabase = useGlobalState((state) => state.supabase);

  const setView = useGlobalState((state) => state.setView);

  const [originalMapData, setOriginalMapData] = useState<DatabaseMap>();
  const fontLoadStatus = useGlobalState((state) => state.fontLoadStatus);
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const [trayFocusedTool, setTrayFocusedTool] = useMapState((state) => [state.trayFocusedTool, state.setTrayFocusedTool], shallow);
  const [trayTwoFocusedTool, setTrayTwoFocusedTool] = useMapState((state) => [state.trayTwoFocusedTool, state.setTrayTwoFocusedTool], shallow);
  const allDatabaseMaps = useGlobalState((state) => state.allDatabaseMaps);
  const [allDatabasePois, setAllDatabasePois] = useGlobalState((state) => [state.allDatabasePois, state.setAllDatabasePois], shallow);
  const setFillMode = useMapState((state) => state.setFillMode);

  const [mapReadyForDisplay, setMapReadyForDisplay] = useMapState((state) => [state.mapReadyForDisplay, state.setMapReadyForDisplay], shallow);
  const [mapFontsLoaded, setMapFontsLoaded] = useState<string>();
  
  const [needsPlayerData, setNeedsPlayerData] = useMapState((state) => [state.needsPlayerData, state.setNeedsPlayerData], shallow);

  const mapDebug = useMapState((state) => state.mapDebug);

  const amGM = useMapState((state) => state.amGM);

  const [openModals, setModalOpen] = useGlobalState((state) => [state.openModals, state.setModalOpen], shallow);

  const [mapDiff, setMapDiff] = useState<any>();

  // When we release a token dragged to the map, we want to wait for one mouse position update to place it on the map, that way we get the accurate position.
  const [pendingTokenAdd, setPendingTokenAdd] = useState<WaitingToAdd>();

  // Map based state
  const [map, setMap] = useMapState((state) => [state.map, state.setMap], shallow);
  const [tokenSidebarOpen, setTokenSidebarOpen] = useMapState((state) => [state.tokenSidebarOpen, state.setTokenSidebarOpen], shallow);

  const [openCommandPalette, setOpenCommandPalette] = useState(false);
  const [myDrawColor, setMyDrawColor] = useMapState((state) => [state.myDrawColor, state.setMyDrawColor], shallow);
  const recentDrawColors = useMapState((state) => state.recentDrawColors);

  const diceRollInputRef = useRef<InputRef>(null);
  const [diceRollString, setDiceRollString] = useState<string>('');
  const rollDice = useGlobalState((state) => state.diceRoll);
  const diceBox = useGlobalState((state) => state.diceBox);
  const lastInitiativeSpot = useRef<number>(-1);
  const [api, contextHolder] = notification.useNotification();
  const setTutorialDrawerOpen = useMapState((state) => state.setTutorialDrawerOpen);

  // Fetch the default map id if we don't have any
  useEffect(() => {
    if (!session || !supabase)
      return;

    if (editingMapId)
      return;

    if (!editingGameId)
      return;

    // We may not have a map ID to load, so let's get the default.
    console.log('Fetching a map since we do not have one');
    const getData = async () => {
      const {error, data} = await supabase
        .from('maps')
        .select('map_id')
        .eq('game_id', editingGameId)
        .eq('default_map', true);

      if (data.length == 0)
        console.error('No default map for game');
      
      const {map_id} = data[0];
      setEditingMapId(map_id);
    };

    getData();
  }, [editingMapId, editingGameId, supabase, session]);
  
  // Notify us when it is our turn
  useEffect(() => {
    if (amGM)
      return;
    
    if (!session)
      return;
    
    if (!editingMapData || !editingMapData.pois)
      return;
    
    if (!editingMapData.initiative) {
      lastInitiativeSpot.current = -1;
      return;
    }
    
    if (lastInitiativeSpot.current != editingMapData.initiative.current) {

      const currentSpot = editingMapData.initiative.spots[editingMapData.initiative.current];
      const matchingPoi = editingMapData.pois.find((poi) => poi.poi_id == currentSpot.poi_id);
      if (matchingPoi) {
        if (matchingPoi.user_id == session.user.id)
          api.info({
            message: `It's your turn!`,
            description: '',
            duration: 3,
          });
      }
      lastInitiativeSpot.current = editingMapData.initiative.current;
    }
  }, [amGM, session, editingMapData]);

  // Fetch all POIs for this map
  useEffect(() => {
    if (!editingMapData)
      return;

    if (editingMapData.pois)
      return;
    
    const isDemo = window.location.href.toLocaleLowerCase().indexOf('demo') > -1;

    if (!session && !isDemo) {
      setMapReadyForDisplay(true);
      return;
    }
    
    if (!allDatabaseMaps)
      return;

    if (editingGameId && editingMapId && (session || isDemo) && supabase) {
      const getData = async () => {
        const {error, data} = await supabase
          .from('map_poi')
          .select('*')
          .in('map_id', allDatabaseMaps.map((map) => map.map_id))
          .eq('owner_id', editingMapData.owner_id);

        let mData = editingMapData;
        if (data) {
          // Data is a list of *all* pois
          let allPois = data.map((item) => ({
            ...(item as unknown as DatabaseMapPoi),
            text_color_override: item.text_color_override === undefined ? undefined : JSON.parse(item.text_color_override),
            fill_color_override: item.fill_color_override === undefined ? undefined : JSON.parse(item.fill_color_override),
            text_padding_override: item.text_padding_override === undefined ? undefined : JSON.parse(item.text_padding_override),
          }));

          if (isDemo) {
            const removedDemoPois = window.localStorage.getItem('demopoisremoved');
            const demoPoiStates = window.localStorage.getItem('demopois');
            
            let removedIds: string[] = [];
            if (removedDemoPois && removedDemoPois.length > 0) {
              removedIds = JSON.parse(removedDemoPois) as string[];
            }
            
            let demoPois: {[key: string]: DatabaseMapPoi} = {}; // Dictionary of poi_id: poi data
            if (demoPoiStates && demoPoiStates.length > 0) {
              demoPois = JSON.parse(demoPoiStates);
            }
            
            allPois = allPois.filter((poi) => !(poi.poi_id in removedIds)); // Remove any demo deleted pois
            
            // Apply any changes we find in our demo POIs list (i.e. pois that we have changed)
            for (let i = 0; i < allPois.length; i++) {
              const thisPoi = allPois[i];
              if (thisPoi.poi_id in demoPois) {
                allPois[i] = {
                  ...thisPoi,
                  ...demoPois[thisPoi.poi_id]
                };
                
                // Delete the key so we can see which POIs we created locally.
                delete demoPois[thisPoi.poi_id];
              }
            }
            
            const remainingNewPois = Object.keys(demoPois);
            for (let i = 0; i < remainingNewPois.length; i += 1) {
              const item = demoPois[remainingNewPois[i]];
              allPois.push({
                ...item,
                // @ts-ignore
                text_color_override: item.text_color_override === undefined ? undefined : JSON.parse(item.text_color_override),
                // @ts-ignore
                fill_color_override: item.fill_color_override === undefined ? undefined : JSON.parse(item.fill_color_override),
                // @ts-ignore
                text_padding_override: item.text_padding_override === undefined ? undefined : JSON.parse(item.text_padding_override),
              })
            }
          }
          
          setAllDatabasePois(allPois);

          mData.pois = allPois.filter((poi) => poi.map_id == editingMapId);
        } else 
          mData.pois = [];

        setEditingMapData(structuredClone(mData));
        setOriginalMapData(structuredClone(mData));
        setMapReadyForDisplay(true);
      };

      getData();
    }
  }, [editingMapData, setEditingMapData, editingGameId, editingMapId, session, supabase, setOriginalMapData, allDatabaseMaps]);
  
  // Once we have the map and pois loaded, AND we have the font that's used for the pois on that map, we can set ready for display.
  // This use effect makes sure that we have all the fonts we'll need for displaying this map loaded. 
  useEffect(() => {
    if (!editingMapData)
      return;
    
    if (editingMapData.pois === undefined)
      return;
 
    // We're already loaded for this map
    if (mapFontsLoaded == editingMapData.map_id)
      return;
  
    const defaultPoiFamily = editingMapData.font_setting && editingMapData.font_setting.length > 0 ? editingMapData.font_setting : DefaultFont;

    if (!(defaultPoiFamily in fontLoadStatus))
      return;

    if (fontLoadStatus[defaultPoiFamily] != 'loaded')
      return;
    
    setMapFontsLoaded(editingMapData.map_id);
  }, [editingMapData, fontLoadStatus, mapFontsLoaded]);

  // As we update the map, track changes (such as moving, renaming, or scaling POIs, etc....)
  useEffect(() => {
    if (!amGM)
      return;
      
    if (originalMapData && editingMapData) {
      // Dont try to diff completely separate maps
      if (originalMapData.map_id !== editingMapData.map_id)
        return;

      // If we are missing a POI we had before, bypass the diff calculation.
      // We do this because by default, if we have a list of three pois and delete the one in position 1, the deep differ sees that NOT as a delete but instead as a change of item 1. 
      // The deep differ works perfectly on creating new POIs (since they are created at the end), or changes.
      
      if (originalMapData.pois !== undefined && editingMapData.pois !== undefined) {
        const originalPoiIds = originalMapData.pois.map((poi) => poi.poi_id) ?? [];
        const newPoiIds = editingMapData.pois.map((poi) => poi.poi_id) ?? [];

        const oldNotInNew = originalPoiIds.filter((id) => !newPoiIds.includes(id));

        if (oldNotInNew.length != 0) {
          setMapDiff(oldNotInNew.map((id) => ({
            type: 'REMOVE',
            oldValue: originalMapData.pois!.find((poi) => poi.poi_id == id),
            path: ['pois', originalMapData.pois!.findIndex((poi) => poi.poi_id == id)]
          })));
          return;
        }
      }

      // Down here we find any individual field changes.
      const d = diff(originalMapData, editingMapData).filter((oneDiff) => 
        !(oneDiff.type == 'REMOVE' && oneDiff.path.length == 1 && oneDiff.path[0] == 'pois') // Remove bogus case where pois array is deleted
      );
      setMapDiff(d);
    }
  }, [amGM, editingMapData, originalMapData, setMapDiff]);

  // This one adds pending tokens to the map as a POI layer
  useEffect(() => {
    if (!amGM)
      return;
    if (!pendingTokenAdd)
      return;
    if (!(window as any).mouseposition)
      return;
    if (!editingMapData)
      return;

    const newAddInterval = setInterval(() => {
      const mp = (window as any).mouseposition as MousePosition;

      // We're not ready to save this one.
      if (mp.time < Date.now())
        return;

      const newMapData = structuredClone(editingMapData);
      let positionToCoordinate = map.getCoordinateFromPixel([(window as any).mouseposition.coordinate[0], (window as any).mouseposition.coordinate[1]]);

      const tokenSize = editingMapData.default_token_size ?? 1;
      if (editingMapData.has_grid) {
        // We need to position this on a grid cell with default size 1.
        const width_grid = editingMapData.grid_line_spacing ?? 100;
        const paddingLeft = editingMapData.grid_padding_left ?? 0;
        const paddingTop = editingMapData.grid_padding_top ?? 0;
        const lineWidth = editingMapData.grid_line_width ?? 2;

        const basedUpon = 250;

        const multiplierIncrement = width_grid / basedUpon;

        let currentMultiplier = tokenSize;
        if (tokenSize != 1)
          currentMultiplier = parseFloat(`${Math.round(tokenSize) * ((editingMapData.grid_line_spacing ?? 100) / 250)}`);

        let currentScaleFactor = currentMultiplier / multiplierIncrement;

        let gridX = (positionToCoordinate[0] - paddingLeft) / (width_grid * currentScaleFactor);
        let gridY = (positionToCoordinate[1] + paddingTop) / (width_grid * currentScaleFactor);

        gridX = Math.floor(gridX * currentScaleFactor) / currentScaleFactor;
        gridY = Math.floor(gridY * currentScaleFactor) / currentScaleFactor;

        positionToCoordinate = [(gridX) * (width_grid * currentScaleFactor) + paddingLeft + (width_grid / 2), (gridY) * (width_grid * currentScaleFactor) - paddingTop + (width_grid / 2)];
      }

      const newPoi = {
        poi_id: uuidv4(),
        map_text: pendingTokenAdd.backgroundImage,
        has_description: false,
        map_id: newMapData.map_id,
        coordinate_x: positionToCoordinate[0],
        coordinate_y: positionToCoordinate[1],
        owner_id: newMapData.owner_id,
        circle_clickbox: false,
        is_image: true,
        font_override: editingMapData.has_grid ? `${Math.round(tokenSize) * ((editingMapData.grid_line_spacing ?? 100) / 250)}` : undefined,
        extra_json_data: pendingTokenAdd.tokenData?.extra_json_data,
        token_id: pendingTokenAdd.tokenData?.token_id,
        user_id: pendingTokenAdd.playerData?.user_id,
        find_zoom_amount: 4,
      } as DatabaseMapPoi;
      newMapData.pois.push(newPoi);
      
      if (pendingTokenAdd.addingExtraSvgInfo) {
        // Go through and make sure our existing first one has the proper extra identifier.
        // Basically if we add a single one, we'd like to render it as is. If we add a second one, 
        // we want the first to become '1' and the second to become '2'
        for (let i = 0; i < newMapData.pois.length; i++) {
          const poi = newMapData.pois[i];
          const isSvg = poi.map_text.startsWith('svg');
          const matchesThis = poi.map_text.includes(pendingTokenAdd.addingExtraSvgInfo);
          const isMatch = isSvg && matchesThis;
          if (isMatch) {
            const thisValue = JSON.parse(poi.map_text.substring(3)) as SvgPathDrawSettings;
            if (!thisValue.extraIdentifier)
            {
              newMapData.pois[i] = {
                ...newMapData.pois[i],
                map_text: `svg${JSON.stringify({
                  ...thisValue,
                  extraIdentifier: '1'
                })}`
              } as DatabaseMapPoi
            }
            break;
          }
          console.log(poi.map_text, isSvg, matchesThis);
        }
      }

      setPendingTokenAdd(undefined);
      setEditingMapData(newMapData);
      clearInterval(newAddInterval);
    }, 10);

    return () => {
      clearInterval(newAddInterval);
    }
  }, [amGM, pendingTokenAdd, setPendingTokenAdd, map, setEditingMapData]);

  // Send updates to the server when we change our map
  useEffect(() => {
    if (!amGM)
      return;

    if (!editingMapData || !mapDiff)
      return;
    
    if (!editingGameData)
      return;

    if (Object.keys(mapDiff).length === 0)
      return;
    
    const isDemo = editingGameData.is_demo;

    const updateData = setTimeout(() => {
      let changeUpsert: {[id: string]: {}} = {};
      
      let hasMapChange = false;
      let mapChange: DatabaseMap;

      mapDiff.forEach((item: any) => {
        if (item.path && item.path.length > 0 && (item.path[0] == 'initiative' || item.path[0] == 'drawings' || item.path[0] == 'timesettings')) {
          hasMapChange = true;

          mapChange = {
            ...editingMapData,
            ...mapChange,
          };
        } else if (item.path && item.path.length > 0 && item.path[0] == 'pois') {
          const index = item.path[1] as number;

          if (item.path.includes('status')) {
            changeUpsert[editingMapData.pois[index].poi_id] = {
              ...editingMapData.pois[index],
              ...changeUpsert[editingMapData.pois[index].poi_id],
              status: editingMapData.pois[index].status ?? [],
            };
          } else if (item.type == 'CHANGE') {
            // We want to collate all changes for the same one.
            changeUpsert[editingMapData.pois[index].poi_id] = {
              ...editingMapData.pois[index],
              ...changeUpsert[editingMapData.pois[index].poi_id],
              [item.path[2]]: item.value,
            };
          } else if (item.type == 'CREATE') {
            changeUpsert[editingMapData.pois[index].poi_id] = {
              ...editingMapData.pois[index],
              ...changeUpsert[editingMapData.pois[index].poi_id]
            };
          } else if (item.type == 'REMOVE') {
            const removeId = async (id: string) => {
              if (isDemo) {
                const removedDemoPois = window.localStorage.getItem('demopoisremoved');
                let removedIds: string[] = [];
                if (removedDemoPois && removedDemoPois.length > 0) {
                  removedIds = JSON.parse(removedDemoPois) as string[];
                }
                
                removedIds.push(id);
                window.localStorage.setItem('demopoisremoved', JSON.stringify(removedIds));
                
                // If this was a local created one, remove it here.
                const demoPoiStates = window.localStorage.getItem('demopois');
                let demoPois: {[key: string]: DatabaseMapPoi} = {}; // Dictionary of poi_id: poi data
                if (demoPoiStates && demoPoiStates.length > 0) {
                  demoPois = JSON.parse(demoPoiStates);
                }
                if (id in demoPois) {
                  delete demoPois[id];
                  window.localStorage.setItem('demopois', JSON.stringify(demoPois));
                }
              } else {
                const {data, error} = await supabase
                  .from('map_poi')
                  .delete()
                  .eq('poi_id', id);
              }
            }

            removeId(item.oldValue.poi_id);
          }
        } else {
          if (item.path && item.path.length == 1) {
            // These are changes to the root map
            if (item.type == 'CHANGE') {
              hasMapChange = true;
              mapChange = {
                ...editingMapData,
                ...mapChange,
                [item.path[0]]: item.value,
              };
            }
          }
        }
      });

      if (Object.keys(changeUpsert).length > 0) {
        const data = Object.keys(changeUpsert).map((key) => changeUpsert[key]);
        const sendData = async (d: any) => {
          if (isDemo) {
            let demoPois: {[key: string]: DatabaseMapPoi} = {}; // Dictionary of poi_id: poi data
            const demoPoiStates = window.localStorage.getItem('demopois');
            if (demoPoiStates && demoPoiStates.length > 0) {
              demoPois = JSON.parse(demoPoiStates);
            }
            
            for (var i = 0; i < data.length; i += 1) {
              const thisPoiChange = data[i] as DatabaseMapPoi;
              demoPois[thisPoiChange.poi_id] = thisPoiChange;
            }
            
            window.localStorage.setItem('demopois', JSON.stringify(demoPois));
          } else {
            const { data, error } = await supabase
              .from('map_poi')
              .upsert(d);
          }
        }
        sendData(data);
      }
      if (hasMapChange) {
        const sendData = async (d: any) => {
          if (isDemo) {
            const stored = window.localStorage.getItem(`demomap-${editingMapId}`);
            let change = {};
            if (stored && stored.length) {
              change = {
                ...JSON.parse(stored)
              };
            }
            
            change = {
              ...change,
              ...d
            };
            window.localStorage.setItem(`demomap-${editingMapId}`, JSON.stringify(change));
          } else {
            const { data, error} = await supabase
              .from('maps')
              .upsert(d)
          }
        }

        /**
         * Prepare mapchange data for sending to database.
         * Pois are stored separately so don't put those here, and also initiative and drawings have to be converted to null or strings.
         */
        if ('pois' in mapChange)
          delete mapChange['pois'];
        if (mapChange.initiative)
          // @ts-ignore
          mapChange.initiative = JSON.stringify(mapChange.initiative);
        else
          mapChange.initiative = null;
        if (mapChange.timesettings)
          // @ts-ignore
          mapChange.timesettings = JSON.stringify(mapChange.timesettings);
        else
          mapChange.timesettings = null;
        if (mapChange.drawings)
          // @ts-ignore
          mapChange.drawings = JSON.stringify(mapChange.drawings);
        else
          mapChange.drawings = null;

        sendData(mapChange);
      }
      setOriginalMapData(structuredClone(editingMapData));
    }, 300);

    return () => clearTimeout(updateData);
  }, [amGM, mapDiff, editingMapData, setOriginalMapData, supabase, session, editingGameData]);
  
  const setDrawColor = useCallback((s: string) => {
    setMyDrawColor(s)
  }, [setMyDrawColor]);

  const colorPickerOptions: PresetsItem[] = [
    {
      label: 'Recommended',
      colors: NormalDrawingColors
    },
    {
      label: 'Recent',
      colors: recentDrawColors,
    },
  ];
  
  const trayRowTwo = trayFocusedTool == ToolType.Draw ? (
    [
      [
        {
          type: ToolType.Unselectable,
          icon: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ),
          click: () => {
            setTrayFocusedTool(ToolType.Pan);
            setTrayTwoFocusedTool(ToolType.Unselectable);
          }
        },
        {
          type: ToolType.Freehand,
          icon: (
            <MdDraw />
          ),
          click: () => {
            setTrayTwoFocusedTool(ToolType.Freehand);
          }
        },
        {
          type: ToolType.Polygon,
          icon: (
            <PiPolygonDuotone />
          ),
          click: () => {
            setTrayTwoFocusedTool(ToolType.Polygon);
          }
        },
        {
          type: ToolType.Circle,
          icon: (
            <BsCircle />
          ),
          click: () => {
            setTrayTwoFocusedTool(ToolType.Circle);
          }
        },
        {
          type: ToolType.Fill,
          icon: (
            <BsPaintBucket />
          ),
          click: () => {
            setTrayTwoFocusedTool(ToolType.Fill);
            setFillMode('fill');
          },
          extra_id: 'fill'
        },
        {
          type: ToolType.Color,
          icon: (
            <ColorPicker presets={colorPickerOptions} disabledAlpha value={myDrawColor} onChange={(_, hex) => {
              setMyDrawColor(hex);
            }} />
          ),
          click: () => {}
        },
        {
          type: ToolType.Eraser,
          icon: (
            <BsEraser />
          ),
          click: () => {
            setTrayTwoFocusedTool(ToolType.Eraser);
          }
        },
      ]
    ]
  ) : [];
  
  if (amGM && trayFocusedTool == ToolType.Draw && editingMapData?.has_grid)
    // @ts-ignore
    trayRowTwo.push([
      {
        type: ToolType.Fill,
        icon: (
          <RiBarricadeFill />
        ),
        click: () => {
          setTrayTwoFocusedTool(ToolType.Fill);
          setFillMode('unwalkable');
        },
        extra_id: 'unwalkable'
      },
    ]);
  
  const clickDice = (dice: string, pattern: RegExp) => {
    const match = pattern.exec(diceRollString);
    console.log(match);

    if (!match) {
      if (!diceRollString)
        setDiceRollString(`1${dice}`);
      else
        setDiceRollString((previous) => `${previous} + 1${dice}`);
    } else {
      const currentNumber = parseInt(match[1]);
      setDiceRollString((previous) => previous.replace(match[0], `${currentNumber + 1}${dice}`));
    }
  }

  const normalUserTray = [
    {
      type: ToolType.Pan, 
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
        </svg>
      ),
      click: () => {
        setTrayFocusedTool(ToolType.Pan);
      }
    },
    {
      type: ToolType.Dice,
      icon: (
        <Popover
          title={"Roll Dice"}
          trigger={"click"}
          open={openModals[DiceRollOpenKey]}
          onOpenChange={(b) => {
            setModalOpen(DiceRollOpenKey, b);
            setTrayFocusedTool(ToolType.Pan);
          }}
          content={(
            <>
              <Input
                placeholder={"Roll... (1d4,2d8,etc...)"}
                className={'rollstring'}
                ref={diceRollInputRef}
                value={diceRollString}
                onChange={(e) => setDiceRollString(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                style={{ width: '100%'}}
              />
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }} className={"rolloptions"}>
                <Button
                  onClick={() => {
                    clickDice('d4', /(\d*)d4/gmi);
                  }}
                  type={"dashed"}
                  style={{ padding: '0px 4px 0px 4px'}}
                >
                  <D4 style={{ width: '32px', height: '32px'}}/>
                </Button>
                <Button
                  onClick={() => {
                    clickDice('d6', /(\d*)d6/gmi);
                  }}
                  type={"dashed"}
                  style={{ padding: '0px 4px 0px 4px'}}
                >
                  <D6 style={{ width: '32px', height: '32px'}}/>
                </Button>
                <Button
                  onClick={() => {
                    clickDice('d8', /(\d*)d8/gmi);
                  }}
                  type={"dashed"}
                  style={{ padding: '0px 4px 0px 4px'}}
                >
                  <D8 style={{ width: '32px', height: '32px'}}/>
                </Button>
                <Button
                  onClick={() => {
                    clickDice('d10', /(\d*)d10/gmi);
                  }}
                  type={"dashed"}
                  style={{ padding: '0px 4px 0px 4px'}}
                >
                  <D10 style={{ width: '32px', height: '32px'}}/>
                </Button>
                <Button
                  onClick={() => {
                    clickDice('d12', /(\d*)d12/gmi);
                  }}
                  type={"dashed"}
                  style={{ padding: '0px 4px 0px 4px'}}
                >
                  <D12 style={{ width: '32px', height: '32px'}}/>
                </Button>
                <Button
                  onClick={() => {
                    clickDice('d20', /(\d*)d20/gmi);
                  }}
                  type={"dashed"}
                  style={{ padding: '0px 4px 0px 4px'}}
                >
                  <D20 style={{ width: '32px', height: '32px'}}/>
                </Button>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '8px', justifyContent: 'space-between' }}>
                <Button
                  type={"dashed"}
                  icon={<FaTrashCan />}
                  onClick={() => setDiceRollString('')}
                  style={{ width: '48%'}}
                >
                  Clear
                </Button>
                <Button
                  type={"primary"}
                  disabled={!diceRollString}
                  icon={<IoMdCheckmark />}
                  loading={diceBox && diceBox.rolling}
                  onClick={() => {
                    rollDice(diceRollString);
                  }}
                  style={{ width: '48%'}}
                >
                  {diceRollString && diceRollString.length > 0 ? 'Roll' : 'Pick Dice'}
                </Button>
              </div>
            </>
          )}
          style={{
            width: '300px'
          }}
        >
          <FaDiceD20/>
        </Popover>
      ),
      click: () => {
        setTrayFocusedTool(ToolType.Dice);
        setModalOpen(DiceRollOpenKey, true);
      }
    },
    {
      type: ToolType.Command,
      icon: <BsCommand/>,
      click: () => {
        setOpenCommandPalette(true);
      },
      checkExtraKeybind: (e: KeyboardEvent, s: ShortcutState) => {
        return (s.shift || s.ctrl) && e.key == ' ';
      }
    },
  ];
  
  if (editingMapData?.has_grid)
    normalUserTray.splice(1, 0,
      {
        type: ToolType.Ruler,
        icon: (
          <LuRuler />
        ),
        click: () => {
          setTrayFocusedTool(ToolType.Ruler);
        }
      });
  
  let canDraw = editingGameData && editingGameData.global_allow_drawing;
  
  if (canDraw && editingGameData.specific_allow_drawing) {
    const parsed = JSON.parse(editingGameData.specific_allow_drawing);
    if (session.user.id in parsed && !parsed[session.user.id])
      canDraw = false;
  }
  
  if (amGM || canDraw)
    normalUserTray.splice(1, 0,
      {
        type: ToolType.Draw,
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />
          </svg>
        ),
        click: () => {
          setTrayFocusedTool(ToolType.Draw);
          setTrayTwoFocusedTool(ToolType.Freehand);
        }
      });

  const backButton = [
    {
      type: ToolType.Back,
      icon: <EnterOutlined/>,
      click: () => {
        setView(View.Menu);
      }
    },
  ];

  const gmTools = [
    {
      type: ToolType.Token,
      icon: <MdOutlineGeneratingTokens/>,
      click: () => {
        setTokenSidebarOpen(!tokenSidebarOpen);
      }
    },
    {
      type: ToolType.History,
      icon: <MdHistory />,
      click: () => {
        setModalOpen(HistoryDrawerModalKey, true);
      },
    },
    {
      type: ToolType.Settings,
      icon: <IoSettingsOutline/>,
      click: () => {
        setModalOpen(MapSettingsModalKey, true);
      }
    },
  ] as TrayTool[];
  
  if (editingGameData?.is_demo)
    gmTools.splice(0, 0, {
      type: ToolType.TutorialTray,
      icon: <LiaChalkboardTeacherSolid />,
      click: () => {
        setTutorialDrawerOpen(true);
      }
    });
  
  const isDemo = window.location.href.toLocaleLowerCase().indexOf('demo') > -1;

  return (
    <>
      {contextHolder}
      {needsPlayerData ? (<CreatePlayerModal needsPlayerData={!amGM && needsPlayerData}
                                             setNeedsPlayerData={setNeedsPlayerData}/>) : (<></>)}
      <SupabaseRoom/>
      {
        !mapReadyForDisplay || (mapFontsLoaded != editingMapData?.map_id) ?
          (
            <div style={{
              width: '100%',
              height: '100%',
              zIndex: 99,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {/* Provided so we load some fonts */}
              <MapSettings open={false} setOpen={() => {}} /> 
              <Spin size='large'/>
            </div>
          ) : (
            <>
              <FeatureDescription/>
              <InnerMapWrapper/>
              <DiceRolling/>
              <ChangeTextPoiColorModal />
              <NewTutorial />
              {session || isDemo ? (
                <MapUI>
                  <ToolTray rows={[
                    //row 1
                    amGM ? [
                      // set 1
                      normalUserTray,
                      gmTools,
                      backButton,
                    ] : [
                      normalUserTray,
                      // backButton,
                    ],
                    trayRowTwo,
                  ]}/>
                </MapUI>
              ) : <></>}
              <MapSettings open={openModals[MapSettingsModalKey]} setOpen={(b: boolean) => setModalOpen(MapSettingsModalKey, b)}/>
              <TokenSidebar addNewTokenToMap={(dragging: string, data: DatabaseToken, addingExtraSvgInfo?: string | undefined) => {
                setPendingTokenAdd({
                  time: Date.now(),
                  backgroundImage: dragging,
                  tokenData: data,
                  addingExtraSvgInfo: addingExtraSvgInfo,
                });
              }}/>
              {amGM ? <HistoryDrawer mapDiff={mapDiff} /> : <></>}
              <PlayerSidebar addNewTokenToMap={(dragging: string, data: DatabaseGamePlayer) => {
                setPendingTokenAdd({
                  time: Date.now(),
                  backgroundImage: dragging,
                  playerData: data,
                })
              }}/>
              <InitiativeTracker/>
              <TimeSlider/>
              {session || isDemo ? (
                <DynamicKMenu openCommandPalette={openCommandPalette} setOpenCommandPalette={setOpenCommandPalette}/>) : <></>}
            {!session && !isDemo ? <OTPLogin /> : <></>}
          </>
        )
      }
    </>
  );
}